import React, { useEffect, useRef } from 'react';
import './MallsMaximizeeFeatures.css';
import F1 from 'assets/images/Malls/F7.jpg';
import F2 from 'assets/images/Malls/F2.jpg';
import F3 from 'assets/images/Malls/F3.jpg';
import icon1 from 'assets/images/Malls/01.png';
import icon2 from 'assets/images/Malls/02.png';
import icon3 from 'assets/images/Malls/03.png';


const MallsMaximizeeFeature = () => {
  const titleRefs = useRef([]); // Refs for the rectangle titles (for scroll animations)

  useEffect(() => {
    // Intersection Observer to detect when the rectangle titles come into view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('title-in-view'); // Add class when title is in view
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the title is visible
    );

    titleRefs.current.forEach((title) => {
      if (title) {
        observer.observe(title); // Observe each rectangle title
      }
    });

    return () => {
      titleRefs.current.forEach((title) => {
        if (title) {
          observer.unobserve(title);
        }
      });
    };
  }, []);

  return (
    <section className="feature-gl-container">
      <div className="feature-gl-header">
        <h1>Optimize chiller plant energy with our phased approach to savings and sustainability.</h1>
      </div>
      {[
        {
          title: 'Phase 1: Primary/Secondary SCADA System & Chiller Optimization',
          content: (
            <div>
              <li>Implementation Timeline: 2-3 months</li>
              <li>Estimated System Energy Saving: 10% - 15%</li>
              <li>Chiller Plant Energy Saving: 6% - 9%</li>
              < br/ >
              *The SEDA Energy Audit Conditional Grant (EACG) of RM60,000 will be applied to identify additional energy-saving opportunities.
            </div>
          ),
          image : F1,
          icon: icon1,
        },
        {
          title: 'Phase 2: CT & Pumps VSD Control',
          content: (
            <div>
              <li>Implementation Timeline: 1 months</li>
              <li>Estimated System Energy Saving: 20% - 30%</li>
              <li>Chiller Plant Energy Saving: 5% - 6%</li>
            </div>
          ),
          image: F2,
          icon: icon2,
        },
        {
          title: 'Phase 3: AHU Optimization',
          content: (
            <div>
              <li>Implementation Timeline: 1 months</li>
              <li>Estimated System Energy Saving: 20% - 30%</li>
              <li>Chiller Plant Energy Saving: 5% - 6%</li>
            </div>
          ),
          image: F3,
          icon: icon3,
        },
        
      ].map((item, index) => (
        <div key={index} className="feature-gl-item expanded">
          {/* Reference the rectangle titles for scroll effect */}
          <div
            className="feature-gl-title"
            ref={(el) => (titleRefs.current[index] = el)}
          >
            <img src={item.icon} alt="Logo" className="features-icon" />
            <h2>{item.title}</h2>
          </div>
          <div className="feature-gl-content">
            <p>{item.content}</p>
            <div className="feature-gl-image">
              <img src={item.image} alt={item.title} style={{ transform: `scale(1)` }} />
            </div>
          </div>
        </div>
      ))}
<div className="feature-gl-header2">
  <h1 style={{ position: "relative" }}>
    <span
      style={{
        fontSize: "140px",
        fontWeight: "bold",
        color: "lightgrey",
        position: "absolute",
        left: "10%",
        top: "-70px",
        zIndex: "0", /* Place the quote in the background */
      }}
      className="quote-start"
    >
      &ldquo;
    </span>
    <i
      style={{
        position: "relative", /* Ensure the text is positioned above */
        zIndex: "1", /* Place the text above the quote */
      }}
    >
      Our chiller optimization approach saves malls <b>16% - 20% on chiller energy</b> <br />
      and <b>10% - 12% on total energy</b>, boosting efficiency and sustainability.
    </i>
    <span
      style={{
        fontSize: "140px",
        fontWeight: "bold",
        color: "lightgrey",
        position: "absolute",
        right: "10%",
        bottom: "-120px",
        zIndex: "0", /* Place the quote in the background */
      }}
      className="quote-end"
    >
      &rdquo;
    </span>
  </h1>
  <hr style={{ marginTop: "30px", border: "1px solid #ccc" }} />
</div>



    </section>
  );
};

export default MallsMaximizeeFeature;
