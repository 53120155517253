import React from "react";
import "./CPFooter.css"; // Import the CSS file

const CPFooter = () => {
  return (
    <footer className="cp-footer">
      <p>© 2024 Square Cloud. All Rights Reserved.</p>
    </footer>
  );
};

export default CPFooter;
