import React, { useState,useRef  } from 'react';
// import PhoneIcon from '@mui/icons-material/Phone';
import { Grid, Box, Typography, Button, TextField,Divider } from '@mui/material';
import { Parallax } from 'react-parallax'; 
import './greenbos.css'; 
import emailjs from 'emailjs-com';
import Image1 from 'assets/images/GreenBOSM/13501.jpg';
import Swal from 'sweetalert2';

import Contactbg from 'assets/images/GreenBOSM/newBG.jpg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Phone, Email, LocationOn,Public  } from '@mui/icons-material'; // Import icons

import icon1 from 'assets/images/GreenBOSM/renewable-energy_11644675.png';
import icon2 from 'assets/images/GreenBOSM/trash_2856870.png';
import icon3 from 'assets/images/GreenBOSM/water-cycle_999049.png';
import icon4 from 'assets/images/GreenBOSM/planet-earth_1598196.png';
import icon5 from 'assets/images/GreenBOSM/carbon-dioxide_8016311.png';
import pic2 from 'assets/images/GreenBOSM/man-suit-is-holding-tablet-front-car-with-graph-it.jpg';
import pic3 from 'assets/images/GreenBOSM/royalty-free-images.jpg';
import pic4 from 'assets/images/GreenBOSM/black-white-image-building-with-graph-it.jpg';
import pic5 from 'assets/images/GreenBOSM/closeup-interlocking-metal-gears-black-white.jpg';
import bg4 from 'assets/images/GreenBOSM/4-2.jpg';

function Page() {

  const contactSectionRef = useRef(null);

  const handleScroll = () => {
    if (contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const [setIsHovering] = useState(false);
  
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    message: '',
  });
  

  

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_6azn8k8', //YOUR_SERVICE_ID
      'template_whtiwsc', //YOUR_TEMPLATE_ID  template_xoam241
      {
        name: formData.name,
        company: formData.company,
        phone: formData.phone,
        email: formData.email,
        message: formData.message,
      },
      'B5hrv7AnNZYglPEOS' //YOUR_USER_ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      
      Swal.fire({
        icon: 'success',
        title: 'Message Sent Successfully!',
        text: 'We will get back to you shortly.',
        confirmButtonColor: '#2196F3',
      });

      setFormData({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
      });
    })
    .catch((error) => {
      console.error('FAILED...', error);

      Swal.fire({
        icon: 'error',
        title: 'Failed to Send Message',
        text: 'Please try again later.',
        confirmButtonColor: '#d33',
      });
    });
  };



  return (
    <Box>
      {/* Hero Section with Parallax and Fade-in Animation */}
      <Parallax
        strength={500} 
        className={`gb-hero-section '}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
        <Box
            sx={{
            position: 'relative',
            width: '100%',
            height: '120vh',
            backgroundImage: `url(${Image1})`, // Background image
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: -1,
            filter: 'brightness(0.5)', // Optional: Adjust image brightness
            }}
    ></Box>

        <Box
        className="gb-hero-content "
        >
        <Typography className="gb-hero-title">
        Drive Sustainability Across <br/>Your Mall for a Greener <br/>Tomorrow 
        </Typography>
        <Typography className="gb-hero-subtitle">
        Start tracking your sustainability performance with ease.
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px', mt: 4 }}>
        
        
            <Button
            className="gb-hero-button"
            onClick={handleScroll}

            >
            Get Started!
            </Button>
        </Box>
    </Box>
    </Parallax>
        
      <Box className="gb-features-section">
      {/* Header and Subheader Section */}
        <Box className="gb-intro">
          {/* Left Side: Header and Subheader */}
          <Box className="s2content-box-web">
            <Typography className="s2content-title-web">GreenBOS</Typography>
            <Divider className="s2content-divider-web" />
            <Typography className="s2content-subtitle-web">Your All-In-One Sustainability Solution</Typography>
          </Box>

          {/* Right Side: Text Content */}
          <Box className="s2content-box2-web">
            <Typography className="s2content-paragraph2-web">
              Our intuitive platform simplifies sustainability tracking, providing you with real-time metrics and comprehensive reports. Empower yourself to make impactful decisions that cut energy costs and enhance green credentials. With GreenBOS, gain the insights needed to drive meaningful change today.
            </Typography>
            <Typography className="s2content-paragraph2-web s2content-paragraph-second2-web">
              Additionally, our comprehensive sustainability reports provide in-depth insights to help you evaluate your progress and set future goals. Whether your aim is to reduce energy costs, optimize resource use, or enhance your green credentials, GreenBOS equips you with the essential insights to drive impactful change.
            </Typography>
          </Box>
        </Box>


        {/* Features Section */}
        <Typography className="gb-features-header" >
        Why Track Your Consumption Data
        </Typography>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box className="gb-feature-card gb-feature-left">
              <Typography className="gb-feature-title">Optimized Data Management for <br/>Every Building in Your Mall</Typography>
              <Typography className="gb-feature-description">
              Managing vast data across multiple locations and facilities can be daunting, requiring precise attention and significant effort.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box className="gb-feature-card gb-feature-right">
              <Typography className="gb-feature-title">Compliance with Regulations <br/>in Mall Operations</Typography>
              <Typography className="gb-feature-description">
              Navigating complex reporting demands intensifies the already substantial workload that mall management teams must handle.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box className="gb-feature-card gb-feature-left">
              <Typography className="gb-feature-title">Rising Operational Expenses <br/>in Malls</Typography>
              <Typography className="gb-feature-description">
              Without accurate insights, operational expenses can spiral out of control, reducing profitability and efficiency across your mall operations.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box className="gb-feature-card gb-feature-right">
              <Typography className="gb-feature-title">Underperforming Assets & <br/>Operations in Shopping Malls
              </Typography>
              <Typography className="gb-feature-description">
              Unrealized efficiency creates bottlenecks, driving up costs and reducing your mall&apos;s potential to achieve peak operational performance.
              </Typography>
            </Box>
          </Grid>

          
        </Grid>
      </Box>
      
      {/* Savings Section */}
      <Box className="gb-savings-section" >
        <Typography className="gb-insights-title">
        GreenBOS: Your Solution to Sustainability Challenge
        </Typography>
        
        <Grid container spacing={4}>
       
          {/* Left Section - Heading */}
          <Grid item xs={12} md={12}>
          <Box className="gb-insights-section">
          {/* Grid Item 1 */}
          <Box className = "gb-insights-item-odd">
            <Box sx={{ flex: 1 }}>
              <LazyLoadImage className="gb-insights-item-pic" src={pic2} alt="Instant Insights" style={{ width: '75%', maxHeight: '75%', borderRadius: '0.52vw' }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography className="gb-insights-item-header">
              Instant Insights for<br/> Smarter Malls
              </Typography>
              <Typography className="gb-insights-item-body">
              Gain live insights with real-time tracking and empower data-driven decisions. Monitor energy, water, and fuel usage across your mall&apos;s facilities for seamless and efficient operations.
              </Typography>
            </Box>
          </Box>

          {/* Grid Item 2 */}
          <Box className="gb-insights-item-even" >
            <Box sx={{ flex: 1 }}>
              <LazyLoadImage className="gb-insights-item-pic"  src={pic3} alt="Simplify Site Management" style={{ width: '75%', maxHeight: '75%', borderRadius: '0.52vw' }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography className="gb-insights-item-header">
              Simplify Mall Management <br/>All in One Place

              </Typography>
              <Typography className="gb-insights-item-body">
              Effortlessly manage multiple mall locations. Access and compare key operational data on a single, intuitive dashboard, ensuring seamless oversight and improved efficiency across all properties.
              </Typography>
            </Box>
          </Box>

          {/* Grid Item 3 */}
          <Box className = "gb-insights-item-odd">
            <Box sx={{ flex: 1 }}>
            <Box  className="gb-insights-item-pic" sx={{ width: '35.5vw', maxWidth:'100vw', maxHeight:'50.5vh', height: '42.5vh', overflow: 'hidden', borderRadius: '0.83vw',marginLeft:'6vw' }}>
              <LazyLoadImage src={pic4} alt="Predictive Analytics" style={{ width: '100%', height: '100%', borderRadius: '0.52vw' }} />
            </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography className="gb-insights-item-header">
              Predictive Analytics for <br/>Smarter Mall Operations

              </Typography>
              <Typography className="gb-insights-item-body">
              Anticipate customer trends, optimize foot traffic, and enhance tenant performance with cutting-edge analytics. Harness actionable insights to streamline operations and stay ahead in the ever-evolving retail landscape.
              </Typography>
            </Box>
          </Box>

          {/* Grid Item 4 */}
          <Box className="gb-insights-item-even" >
            <Box sx={{ flex: 1 }}>
              <LazyLoadImage className="gb-insights-item-pic" src={pic5} alt="Optimized Operations" style={{ width: '75%', height: '75%', borderRadius: '0.52vw' }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography className="gb-insights-item-header">
              Optimized Mall Operations <br/>Without the Effort
              </Typography>
              <Typography className="gb-insights-item-body">
              Streamline your mall&apos;s operations with automation that minimizes costs, reduces manpower needs, and promotes sustainability. Boost efficiency, optimize resource management, and drive profitability with GreenBOS.
              </Typography>
            </Box>
          </Box>
        </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          paddingTop: '5.7vh', // Vertical padding from the top
          paddingBottom: '9.7vh', // Vertical padding from the bottom
          paddingLeft: '2.08vw', // Horizontal padding on the left
          paddingRight: '2.08vw', // Horizontal padding on the right (if needed)
          backgroundColor: '#f9f9f9',
          backgroundImage:`url(${bg4})`,
          borderRadius: '0.52vw',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderTopLeftRadius: '120px', // Rounded corner only at the top-left
          borderTopRightRadius: '120px', // Rounded corner only at the top-right
          marginTop:'-10vh',
          borderTop: '2px solid #D1D1D1;',

        }}
      >
        <Grid 
          container 
          spacing={4} 
        >
        <Grid item xs={12} md={12}>

          <Typography className="icon-page-title">
          Data-Driven Insights by Greenbos
          </Typography>
          </Grid>
        
          {/* Left Section: Items 1-3 */}
          <Grid item xs={12} md={6}>
            <Box className="icon-page-box1">
              {/* Item 1 */}
              <Box className="icon-page-box-individual">
                  <LazyLoadImage className = "icon-page-box-pic-left" src={icon1} alt="Electricity Usage" />
                <Box sx={{ flex: 1 }}>
                  <Typography className="icon-box-individual-title-left">Electricity Usage</Typography>
                  <Typography className="icon-box-individual-body-left">Optimize energy consumption by uncovering and refining your mall&apos;s electricity usage patterns.</Typography>
                </Box>
              </Box>

              {/* Item 2 */}
              <Box className="icon-page-box-individual">
                  <LazyLoadImage className = "icon-page-box-pic-left" src={icon2} alt="Waste Management" />
                <Box sx={{ flex: 1 }}>
                  <Typography className="icon-box-individual-title-left">Waste Management</Typography>
                  <Typography className="icon-box-individual-body-left">Monitor waste generation and optimize recycling practices effectively.</Typography>
                </Box>
              </Box>

              {/* Item 3 */}
              <Box className="icon-page-box-individual">
                  <LazyLoadImage className = "icon-page-box-pic-left" src={icon3} alt="Water Consumption" />
                <Box sx={{ flex: 1 }}>
                  <Typography className="icon-box-individual-title-left">Water Consumption</Typography>
                  <Typography className="icon-box-individual-body-left">Gain insights into water usage and uncover opportunities for conservation and cost savings.</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Section: Items 4-5 */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4,justifyContent: 'center', height: '100%',padding:''}}>
              {/* Item 4 */}
              <Box className="icon-page-box-individual">
                  <LazyLoadImage className = "icon-page-box-pic-right" src={icon4} alt="Environmental Impact" />
                <Box sx={{ flex: 1 }}>
                  <Typography className="icon-box-individual-title-right">Environmental Impact</Typography>
                  <Typography className="icon-box-individual-body-right">Monitor your environmental footprint and make necessary changes for a sustainable future.</Typography>
                </Box>
              </Box>

              {/* Item 5 */}
              <Box className="icon-page-box-individual">
                  <LazyLoadImage className = "icon-page-box-pic-right"  src={icon5} alt="CO2 Emissions" />
                <Box sx={{ flex: 1 }}>
                  <Typography className="icon-box-individual-title-right">CO2 Emissions</Typography>
                  <Typography className="icon-box-individual-body-right">Monitor your carbon footprint to meet regulations and drive sustainability success.</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>



      <Grid 
        ref={contactSectionRef}
        container
        justifyContent="center" // Center items horizontally
        alignItems="center"     // Center items vertically
        sx={{ 
          backgroundImage: `url(${Contactbg})`, 
          backgroundSize:'cover',
          backgroundPosition: 'center',
          scrollSnapAlign: 'start', 
          padding: '100px 20px',
          position: 'relative', // To position the overlay and content correctly
        }}
      >
        {/* Overlay to make content more visible */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',  // Darker overlay for better contrast
            zIndex: 1,
          }}
        ></Box>

        <Box
            className="s6contact-container-web"
          >
            {/* Contact Information Section */}
            <Box
              className="s6contact-box-web">
              <Typography 
                className="s6contact-heading-web">
                Get in Touch <br/>With Us <br/> Today
                
              </Typography>
        

              {/* Contact Details */}
              <Box className="s6contact-info-web">
                <Phone className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                  +603-7497 2558
                </Typography>
              </Box>

              <Box className="s6contact-info-web">
                <Email className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                sales@squarecloud.asia                
                </Typography>
              </Box>

              <Box className="s6flex-start-box-icon-web">
                <LocationOn className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                  D-61-3A Level 3A Jaya One, No. 72A Jalan Prof Diraja Ungku Aziz, 46200 Petaling Jaya, Selangor
                </Typography>
              </Box>

              <Box className="s6flex-start-box-icon2-web">
                <Public className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                  <a href="https://squarecloudasia.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>
                    squarecloudasia.com
                  </a>
                </Typography>
              </Box>
            </Box>

            {/* Contact Form Section */}
            <Box className="centered-form-container"
              sx={{
                width: '55%' ,  // Responsive width for the form
              }}
            >
              <form   className="centered-form" style={{ width: '100%' }} onSubmit={handleSubmit}>
              
                <TextField 
                  label="Your Name" 
                  name="name" 
                  value={formData.name} // Add this to bind the value
                  onChange={handleChange} // Add this to handle changes
                  fullWidth 
                  margin="normal" 
                  required 
           
                  className="s6styled-textfield-web"
                />

                <TextField 
                  label="Company Name" 
                  name="company" 
                  value={formData.company} // Add this to bind the value
                  onChange={handleChange} // Add this to handle changes
                  fullWidth 
                  margin="normal" 
                  required 
                   className="s6styled-textfield-web"
                />

                <TextField 
                  label="Phone Number" 
                  name="phone" 
                  value={formData.phone} // Add this to bind the value
                  onChange={(e) => {
                    // Replace any non-numeric characters
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    setFormData({
                      ...formData,
                      phone: value
                    });
                  }}
                  type="tel" 
                  fullWidth 
                  margin="normal" 
                  required 
                  className="s6styled-textfield-web"
                  
                />

                <TextField 
                  label="Your Email" 
                  name="email" 
                  value={formData.email} // Add this to bind the value
                  onChange={handleChange} // Add this to handle changes
                  type="email" 
                  fullWidth 
                  margin="normal" 
                  required 
                  className="s6styled-textfield-web"
                />

                <TextField 
                  label="Message" 
                  name="message" 
                  value={formData.message} // Add this to bind the value
                  onChange={handleChange} // Add this to handle changes
                  fullWidth 
                  margin="normal" 
                  required 
                  multiline 
                  rows={4}
                  className="s6styled-textfield-web"
                />

            <Button 
              variant="contained" 
              type="submit"
              fullWidth
              className="sec6submit-button-desktop"
            >
            Submit</Button>
              </form>
            </Box>
          </Box>
      </Grid>


      
              {/* <Button
                variant="contained"
                color="primary"
                fullWidth
                className="lp-contact-button"
                sx={{
                  padding: '12px',
                  backgroundColor: '#2196F3', 
                  color: '#fff',
                  fontWeight: 'bold',
                  boxShadow: '0px 8px 20px rgba(33, 150, 243, 0.3)', 
                  borderRadius: '8px', 
                  transition: 'all 0.3s ease', 
                  '&:hover': {
                    backgroundColor: '#42A5F5', 
                    boxShadow: '0px 12px 30px rgba(66, 165, 245, 0.5)', 
                    transform: 'scale(1.02)', 
                  },
                }}
                type="submit"
                >
                  Submit
                </Button> */}
    </Box>
  );
}
export default Page;