import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import ContactUs from "pages/LandingPages/ContactUs";
import Services from "pages/LandingPages/Services";
import Maximizee from "pages/LandingPages/Services/components/pages/Maximizee";
import Crane from "pages/LandingPages/Services/components/pages/Crane";
import Intellisenz from "pages/LandingPages/Services/components/pages/Intellisenz";
import GreenBOS from "pages/LandingPages/Services/components/pages/GreenBOS";
import Monitoring from "pages/LandingPages/Services/components/pages/monitoring";
import IotPage from "pages/LandingPages/Services/components/pages/iotPage";
import VisionMission from "layouts/pages/landing-pages/ManagementTeam";
import OurCoreValues from "layouts/pages/landing-pages/OurCoreValues";
import AboutUS from "pages/LandingPages/AboutUs";
import OurPartner from "pages/LandingPages/Partner";
import GreenBOSLandingPage from "layouts/pages/landing-pages/GreenbosLandingPage/page2";
import MallsGreenBOS from "layouts/pages/landing-pages/MallsGreenBOS/greenbos";
import routes from "routes";
import ChillerLandingPage from "layouts/pages/landing-pages/maximizee/ChillerLandingPage";
import MallsLandingPage from "layouts/pages/landing-pages/MallsLandingPage/malls";
import MallsMaximizee from "layouts/pages/landing-pages/MallsMaximizee/MallsMaximizee";
import Career from "layouts/pages/landing-pages/career-page/CareerPage";
import ChargemanCareer from "layouts/pages/landing-pages/career-chargeman/Chargeman";

export default function App() {
  const { pathname } = useLocation();

  // Initialize Google Analytics
  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-B0CTC4016F";
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-B0CTC4016F");
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/VisionMission" element={<VisionMission />} />
        <Route path="/OurCoreValues" element={<OurCoreValues />} />
        <Route path="/Maximizee" element={<Maximizee />} />
        <Route path="/Crane" element={<Crane />} />
        <Route path="/Intellisenz" element={<Intellisenz />} />
        <Route path="/GreenBOS" element={<GreenBOS />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/monitoring" element={<Monitoring />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/iotPage" element={<IotPage />} />
        <Route path="/AboutUS" element={<AboutUS />} />
        <Route path="/ourPartner" element={<OurPartner />} />
        <Route path="/greenbos-building-sustainability" element={<GreenBOSLandingPage />} />
        <Route path="/maximizee-chiller-optimization" element={<ChillerLandingPage />} />
        <Route path="/malls-sustainability-solutions" element={<MallsLandingPage />} />
        <Route path="/malls-chiller-optimization" element={<MallsMaximizee />} />
        <Route path="/malls-greenbos-sustainability" element={<MallsGreenBOS />} />
        <Route path="/career-internship" element={<Career />} />
        <Route path="/career-chargeman" element={<ChargemanCareer />} />
        <Route path="*" element={<Navigate to="/presentation" />} />
      </Routes>
    </ThemeProvider>
  );
}
