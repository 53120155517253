import React from "react";
import "./MallHero.css";
import heroMallsVideo from "assets/images/Malls/MallVideo4.mp4";
import SQCLogo from "assets/images/Pics _Chiller/removed.png";

function MallHero() {
  return (
    <section className="IES-hero">

      <video className="IES-hero-video" autoPlay muted loop>
        <source src={heroMallsVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="IES-hero-overlay"></div> {/* Overlay for dimming effect */}

      <div className="IES-hero-content">
      <div className='SQC-logo'>
      <a href="https://squarecloudasia.com/presentation" target="_blank" rel="noopener noreferrer">
      <img src= {SQCLogo} />
      </a>
      </div>
      <h2 className="IES-fade-in">
      Optimize Operations with Our AI-Driven and Automated Solution to Reduce Costs,  < br/>
      Improve Sustainability, and Enhance Efficiency

      </h2>
      <a href="#contact-us" className="consultation-button">Arrange a free consultation</a>
      </div>
    </section>
  );
}

export default MallHero;
