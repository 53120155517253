import React, { useState } from 'react';
import { Grid, Box, Typography, Button, TextField } from '@mui/material';
import { Parallax } from 'react-parallax'; 
import { Link as ScrollLink} from 'react-scroll';
import './ChillerLandingPage.css'; 
import Swal from 'sweetalert2';
import { keyframes } from '@mui/system';
import Video1 from 'assets/images/images/videos/video1.mp4';
import Icon1 from 'assets/images/images/icons/capex.png';
import Icon2 from 'assets/images/images/icons/25.png';
import Icon3 from 'assets/images/images/icons/data.png';
import Icon4 from 'assets/images/images/icons/auto.png';
import Icon5 from 'assets/images/images/icons/realtime.png';
import Icon6 from 'assets/images/images/icons/ai.png';
import List4 from 'assets/images/images/list4.jpg';
import Icon7 from 'assets/images/images/icons/highcost.png';
import Icon8 from 'assets/images/images/icons/breakdown.png';
import Icon9 from 'assets/images/images/icons/Inefficient.png';

export default function ChillerLandingPage() {
  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
    "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", 
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", 
    "Croatia", "Cuba", "Cyprus", "Czechia", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", 
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", 
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", 
    "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Italy", "Jamaica", "Japan", 
    "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", 
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", 
    "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", 
    "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", 
    "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", 
    "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", 
    "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", 
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", 
    "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", 
    "Syria", "Taiwan(Province of China)", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", 
    "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];
  
  
  const [setIsHovering] = useState(false);
  
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    message: '',
    country: ''  
  });
  
  const [formErrors, setFormErrors] = useState({
    name: false,
    company: false,
    phone: false,
    email: false,
    message: false,
    country: false  
  });
  

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Reset error for the field when user starts typing
    setFormErrors({
      ...formErrors,
      [e.target.name]: false
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields
    const errors = {
      name: formData.name.trim() === '',
      company: formData.company.trim() === '',
      phone: formData.phone.trim() === '',
      email: formData.email.trim() === '',
      message: formData.message.trim() === '',
      country: formData.country === ''
    };

    setFormErrors(errors);

    if (Object.values(errors).some(error => error)) {
      return;
    }

    // Submit to PHP backend
    try {
      const response = await fetch('https://squarecloudasia.com/SendEmailMaximizee.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully!',
            text: 'We will get back to you shortly.',
            confirmButtonColor: '#2196F3',
          });
          setFormData({
            name: '',
            company: '',
            phone: '',
            email: '',
            message: '',
            country: ''
          });
        } else {
          throw new Error(result.message || 'Failed to send message.');
        }
      } else {
        throw new Error('Failed to send message. Please try again later.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
        confirmButtonColor: '#d33',
      });
    }
  };
  const images = [Icon7, Icon8, Icon9];
  const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;



  return (
    <Box>
      {/* Hero Section with Parallax and Fade-in Animation */}
      <Parallax
        strength={500} 
        className={`lp-hero-section '}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
         <Box sx={{ position: 'relative', width: '100%', height: '120vh' }}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',  // Ensures video takes full width
              height: '100%', // Ensures video takes full height
              zIndex: -1,
              objectFit: 'cover',
              filter: 'brightness(0.5)',
              pointerEvents: 'none',
            }}
          >
            <source src={Video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

        <Box className="lp-hero-content lp-fade-in" sx={{ position: 'relative', zIndex: 1 , marginTop:'-1000px',
            // Styles for screen width up to 1024px (tablets, small laptops)
          '@media (max-width: 1024px)': {
            marginTop: '-1400px', // Adjust margin for tablet-size screens
            marginLeft: '20px', // Optionally adjust for tablet screens
          },
          // Styles for screen width up to 768px (large mobile devices, small tablets)
          '@media (max-width: 768px)': {
            marginTop: '-1200px', // Adjust for smaller tablets and large mobiles
            marginLeft: '15px',
          },
          '@media (max-width: 430px)': {
            marginLeft:'-10px',
            marginTop:'-950px',
          },
        }}>
          <Typography className="lp-hero-title" sx={{ fontWeight: 'bold', fontSize: '4rem', lineHeight:'1.3',
          '@media (max-width: 430px)': {
            fontSize: '2.2rem',  // Make font size smaller for mobile
            marginTop: '-10px', // Add 20px margin top for mobile
          },}}>
           Zero Capex<br/>Zero Worries Energy Savings. <br/>Optimize your chiller with <br/>AI-powered Savings as a Service.
          </Typography>
          <Typography className="lp-hero-subtitle" sx={{fontSize:'1.5rem',
          '@media (max-width: 430px)': {
            fontSize: '1.4rem',  // Make font size smaller for mobile
            // Add 20px margin top for mobile
          },}}>
          No upfront cost, only energy savings. Unlock chiller efficiency with Zero Capex. Subscribe to Our Service.
          </Typography>
          <Box sx={{ display: 'flex', gap: '20px', mt: 4 }}>
            <ScrollLink
              to="lp-features-section"
              smooth={true}
              duration={800}
              offset={-50}
              spy={true}
              activeClass="active"
            >
              <Button
      variant="contained"
      className="lp-hero-button"
      sx={{
        minWidth: '200px',
        padding: '10px 10px',
        fontSize: '1rem',
        fontWeight: 'bold',
        borderRadius: '30px',
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        boxShadow: '0px 10px 20px rgba(33, 150, 243, 0.5)',
        color: 'white !important',  
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0px 12px 25px rgba(33, 150, 243, 0.75)',
        },
        '@media (max-width: 430px)': {
          fontSize: '0.8rem',
          marginTop: '-20px',
          marginLeft: '-10px',
        },
      }}
    >
      Learn More
    </Button>
  </ScrollLink>
  <ScrollLink
    to="lp-contact-section"
    smooth={true}
    duration={1000}
    offset={-50}
    spy={true}
    activeClass="active"
  >
<Button
    
    className="lp-hero-button"
    sx={{
      minWidth: '200px',
      padding: '10px 10px',
      fontSize: '1rem',
      fontWeight: 'bold',
      borderRadius: '30px',
      background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',  
      boxShadow: '0px 10px 20px rgba(76, 175, 80, 0.5)',
      color: 'white !important',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      borderColor: 'transparent',  
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 12px 25px rgba(76, 175, 80, 0.75)',
        background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)', 
        borderColor: 'transparent', 
      },
      '@media (max-width: 430px)': {
        fontSize: '0.8rem',
        marginTop: '-20px',
        marginLeft: '-10px',
      },
    }}
  >
    Subscribe Now!
  </Button>
  </ScrollLink>
</Box>
        </Box>
      </Parallax>

      <Box className="lp-features-section" sx={{ overflow: 'hidden', padding: '40px', boxShadow: '0 6px 20px rgba(0,0,0,0.1)', justifyContent: 'center', // Center the section vertically and horizontally
    minHeight: '100vh', }}>
      {/* Challenger Section */}
      <Box sx={{ padding: '50px 20px', backgroundColor: '#f5f5f5' }}>
            {/* Title Section */}
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize:'2.35rem', color: '#00205b', mb: 6, textAlign: 'center', mt: -5 }}>
              Facing Chiller Energy Efficiency Challenges? 
            </Typography>
            <Grid container direction="column" alignItems="center" spacing={4}>
              
              {/* Individual Challenges */}
              {[
                'Challenge 1: High Energy Consumption and Inconsistent Performance',
                'Challenge 2: Escalating Maintenance Costs and Operational Inefficiencies',
                'Challenge 3: Overcooling, Undercooling, and Inefficient Load Performance'
              ].map((challenge, index) => (
                <Grid
                  item
                  xs={12}
                  md={8}
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%', // Ensure the container takes full width
                  }}
                >
                  <Box
              sx={{
                backgroundColor: '#f9f9f9',
                borderRadius: index === 0 
                  ? '50px 50px 50px 50px'  // First card: rounded on left
                  : index === 1
                  ? '50px 50px 50px 50px'  // Second card: rounded on right
                  : '50px 50px 50px 50px', // Third card: rounded on top
                padding: '30px',
                display: 'flex',
                alignItems: 'center',

                boxShadow: '0px 4px 15px rgba(0,0,0,0.1)',
                flexDirection: index === 1 ? 'row-reverse' : 'row', // Reverse the order for the second card
                position: 'relative',
                width: { xs: '100%', sm: '70%', md: '100%' }, // Reduced width for all cards
                maxWidth: '950px', // Ensure it doesn't exceed a maximum width
                transition: 'box-shadow 0.2s ease-in-out', // Smooth transition for hover effect
                  '&:hover': {
                    boxShadow: '0px 10px 20px rgba(173, 216, 230, 0.8)', // Light blue shadow on hover
                  },
                // Mobile optimization
                '@media (max-width: 430px)': {
                  flexDirection: 'column', // Stack image and text vertically
                  marginLeft: '0', 
                  marginRight: '0', 
                  padding: '20px', 
                }  
              }}
            >
            {/* Image Section */}
            <Box
              component="img"
              src={images[index]} // Assign the correct image based on the index
              alt={challenge}
              sx={{
                width: '70px',
                height: '70px',
                margin: index === 1 ? '0 0 0 20px' : '0 20px 0 0', // Adjust margin based on card orientation
                // Mobile optimization
              '@media (max-width: 430px)': {
                width: '60px', // Reduce image size on mobile
                height: '60px', 
                margin: '0 auto 20px', // Center image on mobile
              },
              }}
            />

            {/* Text Section */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ color: '#00205b', fontWeight: 'bold', fontSize:'1.4rem' }}>
                {challenge}
              </Typography>
              <Typography sx={{ color: '#4D4D4D', marginTop: '10px', textAlign:'justify', ml:'20px',mr:'20px', 
              // Mobile optimization
              '@media (max-width: 430px)': {
                ml: '10px', // Reduce left and right margins on mobile
                mr: '10px',
                textAlign: 'center', // Center text on mobile
              }}}>
                {index === 0
                  ? 'Chillers consume significant energy, and inefficient operations during peak loads can lead to high costs and inconsistent cooling, increasing energy bills.'
                  : index === 1
                  ? 'Inefficient operations contribute to accelerated wear and tear, driving up maintenance expenses and increasing the likelihood of unexpected failures.'
                  : 'Chillers often operate outside optimal temperature ranges, either running too cold and causing significant energy wastage or reacting too slowly, leading to undercooling and reduced performance.'}
              </Typography>
            </Box>

              {/* Decorative Circle */}
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: index === 0 ? '#2196F3' : index === 1 ? '#f48fb1' : '#9575cd',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '-10px',
                  right: index === 1 ? 'auto' : '-10px', // Set 'auto' for right position when index is 1
                  left: index === 1 ? '-10px' : 'auto',
                  // Mobile optimization: Adjust position or remove on mobile
                  '@media (max-width: 430px)': {
                    top: '5px', // Adjust the position of the decorative circle on mobile
                  }
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
<Grid>
    {/* Features Section */}
    <Typography className="lp-features-title" sx={{ fontSize:'2.35rem', fontWeight:'bold'}}>Optimize Now! Subscribe to Our Solution for Chiller Efficiency and Savings</Typography>
      </Grid>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Box className="lp-feature-card">
              <Box className="lp-feature-icon">
                <img src={Icon1} alt="Zero CAPEX Icon" />
              </Box>
              <Typography variant="h5" className="lp-feature-title">Zero CAPEX</Typography>
              <Typography className="lp-feature-description">
              Cut energy costs with Zero Capex. Our Savings as a Service optimizes chiller performance at no upfront cost, delivering enhanced energy efficiency.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box className="lp-feature-card">
              <Box className="lp-feature-icon">
                <img src={Icon2} alt="Guaranteed Savings Icon" />
              </Box>
              <Typography variant="h5" className="lp-feature-title">Up to 25% Savings</Typography>
              <Typography className="lp-feature-description">
              Achieve up to 25% energy savings for chillers only with our advanced AI technology that continually optimizes chiller performance for maximum efficiency.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box className="lp-feature-card">
              <Box className="lp-feature-icon">
                <img src={Icon3} alt="Comprehensive Data Collection Icon" />
              </Box>
              <Typography variant="h5" className="lp-feature-title">Comprehensive Data Analytics</Typography>
              <Typography className="lp-feature-description">
              IoT sensors gather key performance data, including energy consumption and water and electricity usage, providing actionable insights for smarter optimization.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box className="lp-feature-card">
              <Box className="lp-feature-icon">
                <img src={Icon4} alt="Automation Icon" />
              </Box>
              <Typography variant="h5" className="lp-feature-title">Autonomous Chiller Regulation</Typography>
              <Typography className="lp-feature-description">
                AI algorithms autonomously regulate chiller operations in real-time, maintaining optimal performance and reducing energy waste.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box className="lp-feature-card">
              <Box className="lp-feature-icon">
                <img src={Icon5} alt="Remote Monitoring Icon" />
              </Box>
              <Typography variant="h5" className="lp-feature-title">Real-Time Remote Monitoring</Typography>
              <Typography className="lp-feature-description">
                Manage chiller performance from anywhere with our web-based dashboard, enabling efficient and sustainable operations remotely.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box className="lp-feature-card">
              <Box className="lp-feature-icon">
                <img src={Icon6} alt="Predictive Maintenance Icon" />
              </Box>
              <Typography variant="h5" className="lp-feature-title">AI-Driven Predictive Maintenance</Typography>
              <Typography className="lp-feature-description">
                Predict and prevent potential issues before they occur, minimizing downtime and extending the lifespan of your chiller system.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

     {/* Savings Section */}
     <Box className="lp-savings-section" sx={{ padding: '50px', paddingTop:'80px', paddingBottom:'50px', backgroundImage: 'radial-gradient(at 40% 40%, #F7FAFD, transparent), radial-gradient(at 60% 60%, #CADCEF, transparent)',
      backgroundSize: '300% 300%',
      animation: `${gradientAnimation} 3s ease infinite`,
      overflow: 'hidden', 
      '@media (max-width: 430px)': {
        padding: '30px', // Reduce padding on mobile
        paddingTop: '100px', // Adjust for better view
        paddingBottom: '70px',
        backgroundImage: 'radial-gradient(at 40% 40%, #F7FAFD, transparent), radial-gradient(at 60% 60%, #CADCEF, transparent)',
      },
      '@media (max-width: 768px)': {
        padding: '40px', // Adjust padding for tablets
        paddingTop: '120px',
        backgroundImage: 'radial-gradient(at 40% 40%, #F7FAFD, transparent), radial-gradient(at 60% 60%, #CADCEF, transparent)',
      },
      }}>
        
        <Grid container spacing={4}>
          {/* Left Section - Heading */}
          <Grid item xs={12} md={4}>
            <Box className="lp-savings-left-section">
              <Typography  className="lp-savings-title" sx={{ fontWeight: 'bold', fontSize:'2.3rem', color: '#00205b', mb: 4, textAlign: 'left', textTransform: 'none'}}>
              Subscribe to Our Service for Enhanced Efficiency, Performance, and Sustainability
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              {/* First Card */}
              <Grid item xs={12} md={6}>
                <Box
                  className="lp-savings-card"
                  sx={{
                    padding: 4,
                    
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                    transition: 'transform 0.3s ease', // Smooth transition for hover effect
                    '&:hover': {
                      transform: 'scale(1.02)', // Scale up by 5% on hover
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Add shadow effect on hover
                    },
                    '@media (max-width: 430px)': {
                      width: '100%',
                      height: 'auto',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Box>
                    <Typography  sx={{ fontWeight: 'bold', color: '#00205b' }}>
                      Maximize Efficiency, Lower Costs
                    </Typography>
                    <Typography sx={{ mt: 2, textAlign: 'justify', color: '#4D4D4D', padding:'10px', }}>
                    Enhance performance, minimize energy waste, and significantly lower utility costs with our advanced solutions. Reduce unnecessary energy consumption while maintaining optimal performance, leading to considerable cost savings. 
                    </Typography>
                  </Box>
                  <Box sx={{ marginLeft: 2 }}></Box>
                </Box>
              </Grid>

              {/* Second Card */}
              <Grid item xs={12} md={6}>
                <Box
                  className="lp-savings-card"
                  sx={{
                    padding: 4,
                    
                    borderRadius: '10px',
                    height: '100%',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                    '@media (max-width: 430px)': {
                      width: '100%',
                      marginTop: '10px',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', color: '#00205b' }}>
                    Increased Equipment Lifespan
                  </Typography>
                  <Typography sx={{ mt: 2, textAlign: 'justify', color: '#4D4D4D', padding:'10px', }}>
                    Enhance the durability of your equipment with AI-driven optimization. Our technology monitors and fine-tunes operations, reducing wear and tear, extending equipment life, and minimizing costly repairs.
                  </Typography>
                </Box>
              </Grid>

              {/* Third Card */}
              <Grid item xs={12} md={6}>
                <Box
                  className="lp-savings-card"
                  sx={{
                    padding: 4,
                    
                    borderRadius: '10px',
                    height: '100%',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                    '@media (max-width: 430px)': {
                      width: '100%',
                      marginTop: '10px',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', color: '#00205b' }}>
                    Boost Performance with AI-Driven Optimization
                  </Typography>
                  <Typography sx={{ mt: 2, textAlign: 'justify', color: '#4D4D4D' , padding:'10px'}}>
                    Leverage AI technology to monitor and optimize performance in real-time, ensuring peak efficiency in any condition. Our system minimizes energy waste, improves reliability, and enhances operations.
                  </Typography>
                </Box>
              </Grid>

              {/* Fourth Card */}
              <Grid item xs={12} md={6}>
                <Box
                  className="lp-savings-card"
                  sx={{
                    padding: 4,
                    
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                    '@media (max-width: 430px)': {
                      width: '100%',
                      marginTop: '10px',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Box sx={{ marginRight: 2 }}></Box>
                  <Box>
                    <Typography  sx={{ fontWeight: 'bold', color: '#00205b' }}>
                      Sustainability Support
                    </Typography>
                    <Typography sx={{ mt: 2, textAlign: 'justify', color: '#4D4D4D', marginLeft:'-10px' , padding:'10px'}}>
                      Reduce carbon emissions and meet sustainability goals with our energy-efficient solutions. Track progress in real time and align with environmental standards, helping you build a greener future.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>


      {/* Contact Us Section */}
      <Box
        className="lp-contact-section"
        sx={{
          backgroundImage: `url(${List4})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '100px 50px',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={4}>
          {/* Left Section - Big Title */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 'bold',
                fontSize: '4rem',
                lineHeight: '1.2',
                color: '#fff',
                textAlign:'left',
                marginLeft:'20px',
                marginTop:'20px',
                textShadow: '2px 2px 10px rgba(0,0,0,0.5)', 
              }}
            >
              READY TO <br />
              START WITH US?
            </Typography>
          </Grid>

          {/* Right Section - Contact Form */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: 'rgba(255,255,255,0.9)', 
                padding: '30px',
                borderRadius: '10px',
                boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#00205b',
                  fontSize: {
                    xs: '0.9rem', // Font size for small screens (mobile)
                    sm: '1rem', // Font size for slightly larger screens (tablet)
                    md: '1.1rem', // Default font size for medium and up (desktop)
                  },
                  fontWeight: 'bold',
                  marginBottom: {
                    xs: '10px', // Reduced margin for mobile
                    md: '20px', // Default margin for desktop
                  },
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                  flexDirection: {
                    xs: 'column', // Stack elements vertically on smaller screens
                    md: 'row', // Default to row layout on desktop
                  },
                }}
              >
                Fill in the Form or Send us an Email: &nbsp;
                <a
                  href="mailto:sales.squarecloud@gmail.com"
                  style={{
                    color: '#00205b',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    textDecorationColor: '#00205b', 
                    textDecorationThickness: '2px', 
                  }}
                >
                  sales@squarecloud.asia
                </a>
              </Typography>

            <form onSubmit={handleSubmit}>
            <TextField
              select
              label="Select Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              fullWidth
              SelectProps={{
                native: true,
              }}
              className="lp-contact-input"
              sx={{ marginBottom: '20px' }}
              error={formErrors.country}  // Show error state for country
              helperText={formErrors.country ? 'Country is required' : ''} 
            >
              <option value="" disabled></option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </TextField>

              <TextField
                label="Your Name"
                name="name" // Add this
                value={formData.name} // Add this to bind the value
                onChange={handleChange} // Add this to handle changes
                fullWidth
                className="lp-contact-input"
                sx={{ marginBottom: '20px' }}
                error={formErrors.name}  // Show error state
                helperText={formErrors.name ? 'Name is required' : ''}  // Show helper text when error
              />
              <TextField
                label="Company Name"
                name="company" // Add this
                value={formData.company} // Add this to bind the value
                onChange={handleChange} // Add this to handle changes
                fullWidth
                className="lp-contact-input"
                sx={{ marginBottom: '20px' }}
                error={formErrors.company}  // Show error state
                helperText={formErrors.company ? 'Company name is required' : ''}  // Show helper text when error
              />
              <TextField
                label="Phone Number"
                name="phone" // Add this
                value={formData.phone} // Add this to bind the value
                onChange={handleChange} // Add this to handle changes
                fullWidth
                className="lp-contact-input"
                sx={{ marginBottom: '20px' }}
                error={formErrors.phone}  // Show error state
                helperText={formErrors.phone ? 'Phone number is required' : ''}  // Show helper text when error
              />
              <TextField
                label="Your Email"
                name="email" // Add this
                value={formData.email} // Add this to bind the value
                onChange={handleChange} // Add this to handle changes
                fullWidth
                className="lp-contact-input"
                sx={{ marginBottom: '20px' }}
                error={formErrors.email}  // Show error state
                helperText={formErrors.email ? 'Email is required' : ''}  // Show helper text when error
              />
              <TextField
                label="Message"
                name="message" // Add this
                value={formData.message} // Add this to bind the value
                onChange={handleChange} // Add this to handle changes
                fullWidth
                multiline
                rows={4}
                className="lp-contact-input"
                sx={{ marginBottom: '20px' }}
                error={formErrors.message}  // Show error state
                helperText={formErrors.message ? 'Message is required' : ''}  // Show helper text when error
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="lp-contact-button"
                sx={{
                  padding: '12px',
                  maxWidth:'670px',
                  backgroundColor: '#2196F3', 
                  color: '#fff',
                  fontWeight: 'bold',
                  boxShadow: '0px 8px 20px rgba(33, 150, 243, 0.3)', 
                  borderRadius: '8px', 
                  transition: 'all 0.3s ease', 
                  '&:hover': {
                    backgroundColor: '#42A5F5', 
                    boxShadow: '0px 12px 30px rgba(66, 165, 245, 0.5)', 
                    transform: 'scale(1.02)', 
                  },
                }}
                type="submit"
                >
                  Send Message
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
