import React from 'react';
import Content from './MallsMaximizeeContent';
import ContactUs from './MallsMaximizeeContactUs';
import MallHero from './MallsMaximizeeHero';
import MallsMaximizeeFeature from './MallsMaximizeeFeatures';
import MallStage from './MallStage';

function MallsMaximizee() {

  return (
    <div className="App">
      <MallHero />
      <div className="MallsMaxi-content">
      <Content />
      <MallsMaximizeeFeature />
      <MallStage />
      <div id="contact-us">
          <ContactUs />
        </div>
    </div>
  </div>
  );
}

export default MallsMaximizee;
