import React from 'react';
import './MallsMaximizeeContent.css'; // Import CSS file for styling
import icon1 from 'assets/images/images/icons/problem.png';


const MallsMaxiContent = () => {
  return (
    <div className="MallsMaxi-content-container">
      <div className="MallsMaxi-content-header">
        <h3>Facing Rising Energy Costs Due to Underperforming Chillers in Your Mall?</h3>
      </div>

      <div className="MallsMaxi-content-cards">
        {/* Card 1 */}
<div className="MallsMaxi-content-card">
  <div className="MallsMaxi-card-title">
    <img src={icon1} alt="Logo" className="MallsMaxi-icon" />
    <h3>High Energy & Poor Cooling</h3>
  </div>
  <div className="MallsMaxi-content-card-details">
    <p>
    Chillers waste energy and provide uneven cooling, increasing costs and reducing comfort in the mall.
    </p>
  </div>
</div>

{/* Card 2 */}
<div className="MallsMaxi-content-card">
  <div className="MallsMaxi-card-title">
    <img src={icon1} alt="Logo" className="MallsMaxi-icon" />
    <h3>High Maintenance & Failures</h3>
  </div>
  <div className="MallsMaxi-content-card-details">
    <p>
      Poor chiller performance causes higher maintenance costs and unexpected breakdowns.
    </p>
  </div>
</div>

{/* Card 3 */}
<div className="MallsMaxi-content-card">
  <div className="MallsMaxi-card-title">
    <img src={icon1} alt="Logo" className="MallsMaxi-icon" />
    <h3>Cooling Imbalances</h3>
  </div>
  <div className="MallsMaxi-content-card-details">
    <p>
      Inefficient cooling leads to energy waste and discomfort due to overcooling or undercooling.
    </p>
  </div>
</div>

      </div>
    </div>
  );
};

export default MallsMaxiContent;
