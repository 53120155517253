import React from 'react';
import Content from './CareerContent';
import Navbar from './CPNavbar';
import Hero from './CPHero';
import Footer from './CPFooter';

function Career() {
  return (
    <>
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </>
  );
}

export default Career;
