import React, { useEffect, useRef } from 'react';
import './MallStage.css';
import F1 from 'assets/images/Malls/F9.jpg';
import F2 from 'assets/images/Malls/F10.jpg';
import F3 from 'assets/images/Malls/F11.jpg';
import F4 from 'assets/images/Malls/F4.jpg';
import F5 from 'assets/images/Malls/F5.jpg';
import F6 from 'assets/images/Malls/F6.jpg';

const newsletterData = [
  {
    title: 'Zero CapEx',
    description: (
      <div>
        Cut energy costs with Zero CapEx. Our Savings as a Service optimizes chiller performance at no upfront cost, delivering enhanced energy efficiency.
      </div>
    ),
    image: F1,
  },
  
  {
    title: 'Up to 25% Savings',
    description: (
      <div>
        Achieve up to 25% energy savings for chillers only with our advanced AI technology that continually optimizes chiller performance for maximum efficiency.
      </div>
    ),
    image: F2,
  },
  {
    title: 'Comprehensive Data Analytics',
    description: (
      <div>
        IoT sensors gather key performance data, including energy consumption and water and electricity usage, providing actionable insights for smarter optimization.
      </div>
    ),
    image: F3,
  },
  {
    title: 'Autonomous Chiller Regulation',
    description: (
      <div>
        AI algorithms autonomously regulate chiller operations in real-time, maintaining optimal performance and reducing energy waste.
      </div>
    ),
    image: F4,
  },
  {
    title: 'Real-Time Remote Monitoring',
    description: (
      <div>
        Manage chiller performance from anywhere with our web-based dashboard, enabling efficient and sustainable operations remotely.
      </div>
    ),
    image: F5,
  },
  {
    title: 'AI-Driven Predictive Maintenance',
    description: (
      <div>
        Predict and prevent potential issues before they occur, minimizing downtime and extending the lifespan of your chiller system.
      </div>
    ),
    image: F6,
  },
];

const MallStage = () => {
  const newsletterRefs = useRef([]);
  const headingRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    newsletterRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      newsletterRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  return (
    <div className="MallStage-container">
      <h2 ref={headingRef} className="MallStage-section-title">Subscribe Now for Cost Savings, Enhanced Performance, and Sustainability</h2>
      <div className="MallStage-card-list">
        {newsletterData.map((item, index) => (
          <div 
            className="MallStage-card" 
            key={index}
            ref={el => newsletterRefs.current[index] = el}
          >
            <div className="MallStage-card-image">
              <img 
                src={item.image} 
                alt={item.title}
              />
            </div>
            <div className="MallStage-card-content">
              <div className="MallStage-card-title">{item.title}</div>
              <div className="MallStage-card-hover-cover"></div>
              <div className="MallStage-card-excerpt">
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MallStage;
