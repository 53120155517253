import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import emailjs from 'emailjs-com';
import Alert from '@mui/material/Alert';


function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      email: formData.email,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_8btqyep', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setFormData({ name: '', subject: '', email: '', message: '' });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };

  return (
    <MKBox
      component="section"
      sx={{
        minHeight: "75vh",
        width: "100%",
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
        py: 6, // Increased padding for better layout
      }}
    >
      <Container>
        <Grid container spacing={6} alignItems="flex-start">
          {/* Left Section: Map and Contact Info */}
          <Grid item xs={12} md={6}>
            <MKTypography
              variant="h3"
              textAlign="left"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: { fontSize: size["2xl"] },
              })}
            >
              Find Us Here
            </MKTypography>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.77776582993!2d101.6380689!3d3.1094019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4b21fec6c627%3A0xd6bfcff75b366db5!2sSquareCloud%20Malaysia!5e0!3m2!1sen!2smy!4v1723619866998!5m2!1sen!2smy"
              width="100%"
              height="300"
              style={{ border: 0, marginTop: "16px", borderRadius: "8px" }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            />
            <MKTypography
              variant="body2"
              color="#59535c"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: "1.2rem",
                lineHeight: "1.5",
                mt: 3,
              }}
            >
              <EmailIcon sx={{ mr: 1, fontSize: 'inherit' }} />
              sales@squarecloud.asia
            </MKTypography>
            <MKTypography
              variant="body2"
              color="#59535c"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: "1.2rem",
                lineHeight: "1.5",
                mt: 2,
              }}
            >
              <PhoneIcon sx={{ mr: 1, fontSize: 'inherit' }} />
              0374972558
            </MKTypography>
            <MKTypography
              variant="body2"
              color="#59535c"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                fontSize: "1.2rem",
                lineHeight: "1.5",
                mt: 2,
              }}
            >
              <LocationOnIcon sx={{ mr: 1, fontSize: 'inherit', mt: '4px' }} />
              <span>
                D-61-3A Level 3A Jaya One,
                <br />
                No. 72A Jalan Prof Diraja Ungku Aziz,
                <br />
                46200 Petaling Jaya Selangor
              </span>
            </MKTypography>
          </Grid>

          {/* Right Section: Contact Form */}
          <Grid item xs={12} md={6}>
            <MKTypography
              variant="h3"
              textAlign="left"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: { fontSize: size["2xl"] },
              })}
            >
              Contact Us Now
            </MKTypography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                required
                sx={{ mb: 2 }}
              />
              <MKButton
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#59535c',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#59535c' },
                  textTransform: 'none',
                }}
              >
                SUBMIT
              </MKButton>
              {alertVisible && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  There you go! Your email is on its way to SquareCloud.
                </Alert>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactForm;
