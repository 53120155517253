import React from "react";
import "./MallsMaximizeeHero.css";
import heroMallsVideo from "assets/images/Malls/MallVideo2.mp4";
import SQCLogo from "assets/images/Pics _Chiller/removed.png";

function MallsMaximizeeHero() {
  return (
    
    <section className="MallsMaxi-hero">
      <video className="MallsMaxi-hero-video" autoPlay muted loop>
        <source src={heroMallsVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="MallsMaxi-hero-overlay"></div> {/* Overlay for dimming effect */}

      <div className="MallsMaxi-hero-content">
      <div className='MMSQC-logo'>
      <a href="https://squarecloudasia.com/presentation" target="_blank" rel="noopener noreferrer">
      <img src= {SQCLogo} />
      </a>
      </div>
      <h2 className="MallsMaxi-fade-in">Maximize Energy Savings with AI-Powered Chiller Management</h2>
<p className="MallsMaxi-fade-in">
  Zero CapEx – Achieve Maximum Efficiency & Savings<br />
  Subscribe and Unlock Up to 25% Energy Savings
</p>

        <a href="#contact-us" className="MM-subscribe-now-button">Subscribe Now</a>

      </div>
    </section>
  );
}

export default MallsMaximizeeHero;
