import React from "react";
import "./CPNavbar.css"; // Import the CSS file
import logo from "assets/images/Pics _Chiller/removed.png"; // Correctly import the logo image

const Navbar = () => {
  return (
    <nav className="cp-navbar">
      <div className="cp-placeholder"></div>
      <div className="cp-logo-container">
      <a href="https://squarecloudasia.com/presentation" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="Square Cloud Logo" className="cp-logo" />
      </a>
      </div>
      <div className="cp-placeholder"></div>
    </nav>
  );
};

export default Navbar;
