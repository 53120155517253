import React from "react";
import "./CPHero.css"; // Import the CSS file
import cpVideoPath from 'assets/images/CareerPage/CPHero3.mp4';



const CPHero = () => {
  return (
    <div className="cp-hero-container">
      {/* Video Section */}
      <video className="cp-hero-video" autoPlay loop muted>
        <source src={cpVideoPath} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Hero Content */}
      <div className="cp-hero-content">
        <h1 className="cp-hero-title">Expand Your Potential with Us</h1>
        <p className="cp-hero-subtitle">Let&apos;s go further together, with the right tools and mindsets. Because when empowered, anything is possible.</p>
      </div>
    </div>
  );
};

export default CPHero;
