// Pages
import ManagementTeam from "layouts/pages/landing-pages/ManagementTeam";
import AboutUs from "layouts/pages/landing-pages/about-us";
import Services from "pages/LandingPages/Services"
import ContactUs from "layouts/pages/landing-pages/contact-us";
import ChillerForm from "layouts/pages/landing-pages/chillerform";
import SavingsForm from "layouts/pages/landing-pages/savingsform";
import AddInfo from "layouts/pages/landing-pages/add-info";
import GetData from "layouts/pages/landing-pages/get-data";
import Maximizee from "pages/LandingPages/Services/components/pages/Maximizee";
import Monitoring from "pages/LandingPages/Services/components/pages/monitoring";
import IotPage from "pages/LandingPages/Services/components/pages/iotPage";
import PartnerPage from "pages/LandingPages/Partner";
import CareerPage from "layouts/pages/landing-pages/career-page/CareerPage"


const routes = [
  {
    name: "Services ",
    dropdown: true,
    collapse: [
      {
        name: "Our Services",
        route: "/LandingPages/Services",
        component: <Services />
      },
      {
        name: "Maximizee",
        route: "/LandingPages/Services/components/pages/Maximizee",
        component: <Maximizee />
      },
      {
        name: "Crane & Monitoring",
        route: "/LandingPages/Services/components/pages/monitoring",
        component: <Monitoring />
      },
      {
        name: "IoT Architecture",
        route: "/LandingPages/Services/components/pages/iotPage",
        component: <IotPage />
      },
    ]
  },
  {
    name: "Careers",
    route: "/pages/landing-pages/career-page/CareerPage",
    component: <CareerPage />

  },
  {
    name: "Our Team",
    route: "/pages/landing-pages/ManagementTeam",
    component: <ManagementTeam />

  },
  {
    name: "About Us",
    route: "/pages/landing-pages/about-us",
    component: <AboutUs />

  },
  {
    name: "contact us",
    route: "/pages/landing-pages/contact-us",
    component: <ContactUs />,
  },
  {
    name: "our partner",
    route: "pages/LandingPages/Partner",
    component: <PartnerPage />,
  },

  {
    name: "Energy Saving Calculator",
    description: "See all sections",
    dropdown: true,
    collapse: [
      {
        name: "SavingsForm",
        route: "/pages/landing-pages/savingsform",
        component: <SavingsForm />,
      },
      {
        name: "Chiller Form",
        route: "/pages/landing-pages/chillerform",
        component: <ChillerForm />,
      },
      {
        name: "Additional Information",
        route: "/pages/landing-pages/add-info",
        component: <AddInfo />,
      },
    ],
  },
  {
    route: "/pages/landing-pages/get-data",
    component: <GetData />,

  },
];

export default routes;

