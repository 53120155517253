import React from 'react';
import Content from './MallContent';
import ContactUs from './MallContactUs';
import MallHero from './MallHero';


function Malls() {

  return (
    <div className="App">
      <MallHero />
      <div className="HeroMalls-content">
      <Content />
      <div id="contact-us">
      <ContactUs />
      </div>
    </div>
  </div>
  );
}

export default Malls;
