import React, { useState } from "react";
import axios from "axios";
import icon1 from "assets/images/CareerPage/calendar.png";
import icon2 from "assets/images/CareerPage/location.png";
import icon3 from "assets/images/CareerPage/share.png";
import icon4 from "assets/images/CareerPage/email.png";
import icon5 from "assets/images/CareerPage/phone.png";
import "./ChargemanContent.css";
import Swal from "sweetalert2";

const ChargemanContent = () => {
    const [showForm, setShowForm] = useState(false);
    const [copyMessage, setCopyMessage] = useState("");
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        resume: null,
    });
    const [formErrors, setFormErrors] = useState({});

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const handleCopyLink = () => {
        const link = window.location.href;
        navigator.clipboard.writeText(link).then(() => {
            setCopyMessage("Link copied to clipboard!");
            setTimeout(() => setCopyMessage(""), 2000);
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.firstName) errors.firstName = "First Name is required.";
        if (!formData.lastName) errors.lastName = "Last Name is required.";
        if (!formData.email) {
            errors.email = "Email Address is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email Address is invalid.";
        }
        if (!formData.phoneNumber) errors.phoneNumber = "Phone Number is required.";
        if (!formData.resume) {
            errors.resume = "Resume is required.";
        } else if (formData.resume.type !== "application/pdf") {
            errors.resume = "Only PDF files are allowed for resume upload.";
        }
        return errors;
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate the form
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            const formDataToUpload = new FormData();
            formDataToUpload.append("firstName", formData.firstName);
            formDataToUpload.append("lastName", formData.lastName);
            formDataToUpload.append("email", formData.email);
            formDataToUpload.append("phoneNumber", formData.phoneNumber);
            formDataToUpload.append("resume", formData.resume);
    
            try {
                const response = await axios.post(
                    "https://squarecloudasia.com/upload-and-email.php",
                    formDataToUpload,
                    {
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                );
    
                console.log("Backend Response:", response.data);
    
                // Validate the backend response structure
                if (response.data && typeof response.data.success !== "undefined") {
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: "Application Sent!",
                            text: response.data.message || "Your application has been submitted successfully.",
                        });
                        // Clear form data
                        setFormData({
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            resume: null,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: response.data.message || "An error occurred. Please try again later.",
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Unexpected response from the server. Please contact support.",
                    });
                }
            } catch (error) {
                console.error("Submission error:", error);
    
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: error.response?.data?.message || "Failed to submit the application. Please try again later.",
                });
            }
        } else {
            setFormErrors(errors);
        }
    };
    
    
    
    

    return (
        <div className="Chargeman-page">
            <div className="Chargeman-job-description">
                <h2>We&apos;re Hiring: A4 Chargeman</h2>
                <h3>2025 - Job Opening</h3>                  
                <p>
                    Are you a skilled A4 Chargeman looking for an exciting opportunity? Join our dynamic team as we expand our operations across<strong> Kuala Lumpur, Penang, and Johor Bahru</strong>!
                </p>
                
                <p>
                <h3>Key Responsibilities:</h3>
                    <ol>
                        <li>Oversee electrical installations and maintenance.</li>
                        <li>Lay cables, install plug points, and manage distribution panel maintenance (DPM).</li>
                        <li>Ensure compliance with safety and regulatory standards.</li>
                        <li>Provide technical support for electrical systems.</li>
                    </ol>
                </p>
                <p>
                <h3>Requirements:</h3>
                    <ul>
                        <li>A4 Chargeman certification with relevant experience.</li>
                        <li>Strong knowledge of electrical systems and safety protocols.</li>
                        <li>Problem-solving skills and attention to detail.</li>
                        <li>Willingness to work in<strong> KL, Penang, or JB.</strong></li>
                    </ul>
                </p>
            </div>
            <div className="Chargeman-form-section">
                {!showForm ? (
                    <>
                        <button className="Chargeman-apply-button" onClick={toggleForm}>
                            Apply for Careers Here
                        </button>
                        <div className="Chargeman-event-details">
                            <p>
                                <span className="Chargeman-icon">
                                    <img src={icon1} alt="Calendar Icon" />
                                </span>
                                <strong>Dec 21st 2024 - Dec 31st 2025</strong>
                                <br />
                                9:00 AM - 6:00 PM | GMT +08
                            </p>
                            <p>
                                <span className="Chargeman-icon">
                                    <img src={icon4} alt="Email Icon" />
                                </span>
                                <strong>admin@squarecloud.asia</strong>
                            </p>
                            <p>
                                <span className="Chargeman-icon">
                                    <img src={icon5} alt="Phone Icon" />
                                </span>
                                <strong>03-7497-2558</strong>
                            </p>
                            <p>
                                <span className="Chargeman-icon">
                                    <img src={icon2} alt="Location Icon" />
                                </span>
                                <strong>SquareCloud Malaysia</strong>
                                <br />
                                D-61-3A Level 3A Jaya One,
                                No. 72A Jalan Prof Diraja Ungku Aziz,
                                46200 Petaling Jaya, Selangor, Malaysia
                            </p>
                            <p className="Chargeman-share-link" onClick={handleCopyLink}>
                                <span className="Chargeman-icon">
                                    <img src={icon3} alt="Share Icon" />
                                </span>
                                <strong>Share this event</strong>
                            </p>
                            {copyMessage && <span className="Chargeman-copy-message">{copyMessage}</span>}
                        </div>
                    </>
                ) : (
                    <form className="Chargeman-application-form" onSubmit={handleSubmit}>
                        <h3>Explore Your Career Opportunities</h3>
                        <div className="Chargeman-form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="Enter your first name"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                            {formErrors.firstName && <span className="Chargeman-error">{formErrors.firstName}</span>}
                        </div>
                        <div className="Chargeman-form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Enter your last name"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                            {formErrors.lastName && <span className="Chargeman-error">{formErrors.lastName}</span>}
                        </div>
                        <div className="Chargeman-form-group">
                            <label>Email Address</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {formErrors.email && <span className="Chargeman-error">{formErrors.email}</span>}
                        </div>
                        <div className="Chargeman-form-group">
                            <label>Phone Number</label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                placeholder="Enter your phone number"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                            {formErrors.phoneNumber && <span className="Chargeman-error">{formErrors.phoneNumber}</span>}
                        </div>
                        <div className="Chargeman-form-group">
                            <label>Upload Resume</label>
                            <input
                                type="file"
                                name="resume"
                                accept=".pdf"
                                onChange={handleChange}
                            />
                            {formErrors.resume && <span className="Chargeman-error">{formErrors.resume}</span>}
                        </div>
                        <button type="submit" className="Chargeman-submit-button">
                            Submit Application
                        </button>
                        <button type="button" className="Chargeman-cancel-button" onClick={toggleForm}>
                            Cancel
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ChargemanContent;
