import React from 'react';
import './MallContent.css'; // Import CSS file for styling
import F1 from 'assets/images/Malls/Mall11.jpg';



const SEDAContent = () => {
  return (
    <div className="grant-content-container">
      <div className="grant-content-header">
        {/* <h3>Smarter Energy Solutions for Sustainable Malls</h3> */}
        <h3>Subscribe to Our Energy Efficiency Solutions and Start Saving in Just 2 Months</h3>
        <p>
        AI-driven optimization, real-time monitoring, and grant support to cut costs, save energy, and achieve sustainability goals.
        </p>
      </div>

      <div className="grant-content-cards">
        {/* Card 1 */}
<div className="grant-content-card">
  <div className="grant-content-card-image">
    <img src={F1} alt="Maximizee Chiller Optimization" />
  </div>
  <div className="grant-content-card-details">
    <h3>Maximizee Chiller Optimization for Malls</h3>
    <p>
  AI-powered chiller optimization for malls, achieving up to 25% energy savings with zero upfront cost (Zero Capex). Reduces costs, extends equipment life, lowers emissions, and supports sustainability.

</p>

    <a href="https://squarecloudasia.com/maximizee-chiller-optimization" className="learn-more-link">
      Learn More <span className="arrow">→</span>
    </a>
  </div>
</div>
    
      </div>
    </div>
  );
};

export default SEDAContent;
