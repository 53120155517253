import React from 'react';
import ChargemanContent from './ChargemanContent';
import Navbar from '../career-page/CPNavbar';
import Hero from '../career-page/CPHero';
import Footer from '../career-page/CPFooter';

function Career() {
  return (
    <>
      <Navbar />
      <Hero />
      <ChargemanContent />
      <Footer />
    </>
  );
}

export default Career;
