import React from 'react';
import { useEffect ,useState, useRef } from 'react';
import { Grid, Box, Typography,Button,TextField } from '@mui/material';
import background1 from 'assets/images/GreenBOS/photo-exterior-modern-buildings.jpg';
import pic2 from 'assets/images/GreenBOS/man-suit-is-holding-tablet-front-car-with-graph-it.jpg';
import pic3 from 'assets/images/GreenBOS/royalty-free-images.jpg';
import pic4 from 'assets/images/GreenBOS/black-white-image-building-with-graph-it.jpg';
import pic5 from 'assets/images/GreenBOS/closeup-interlocking-metal-gears-black-white.jpg';
import icon1 from 'assets/images/GreenBOS/renewable-energy_11644675.png';
import icon2 from 'assets/images/GreenBOS/trash_2856870.png';
import icon3 from 'assets/images/GreenBOS/water-cycle_999049.png';
import icon4 from 'assets/images/GreenBOS/planet-earth_1598196.png';
import icon5 from 'assets/images/GreenBOS/carbon-dioxide_8016311.png';
import arrowIcon from 'assets/images/GreenBOS/down-16046465-unscreen.gif'; // Make sure to replace with your actual image path
import background2 from 'assets/images/GreenBOS/Greenbuildings.jpg'; // Import your GreenBOS logo
import background3 from 'assets/images/GreenBOS/skyscraper-modern-office-buildings-city.jpg'; // Import your GreenBOS logo
import background4 from 'assets/images/GreenBOS/beautiful-city-view.jpg'; // Import your GreenBOS logo
import background5 from 'assets/images/GreenBOS/newBG.jpeg';
//import SQ from 'assets/images/GreenBOS/sq.png';
import emailjs from 'emailjs-com';
import { Phone, Email, LocationOn,Public  } from '@mui/icons-material'; // Import icons
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import './page2.css'; // Import your custom CSS file
import background6 from 'assets/images/GreenBOS/26660.jpg';
import Divider from '@mui/material/Divider'; // Import Divider from MUI
import './gb.css';


function Page2() {
  const [isMobile, setIsMobile] = useState(false);
  const contactRef = useRef(null); // Reference for Contact section
  const page2Ref = useRef(null); // Reference for SlideshowComponent section
  const form = useRef();
  const [success, setSuccess] = useState(false); // State to track form submission status
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900); // Set a breakpoint for mobile, e.g., 768px
    };
    
    // Set initial state
    handleResize();

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);
    
    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    
    
    emailjs.sendForm('service_6azn8k8', ' template_whtiwsc', form.current, 'B5hrv7AnNZYglPEOS')
      .then((result) => {
          console.log(result.text);
          setSuccess(true); // Set success to true on successful submission
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
      }, (error) => {
          console.log(error.text);
      });
  };

  
  const sendEmailD = (e) => {
    e.preventDefault();
    
    
    emailjs.sendForm('service_6azn8k8', ' template_whtiwsc', form.current, 'B5hrv7AnNZYglPEOS')
      .then((result) => {
          console.log(result.text);
          setSuccess(true); // Set success to true on successful submission
      }, (error) => {
          console.log(error.text);
      });
  };

  const handleScrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const handleScrollToPage2 = () => {
    if (page2Ref.current) {
      page2Ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const MobileVersion = () => (
    <>
      <Grid
        container
        sx={{
          height: '100vh',
          backgroundImage: `url(${background2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          color: 'white',
          textAlign: 'center',
          padding: '0 10px', // Adjust padding for mobile
        }}
      >
        {/* Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1,
          }}
        ></Box>

        {/* Content */}
        <Grid
          item
          xs={12}
          sx={{
            zIndex: 2, // Ensure content is above overlay
            marginTop: '80px', // Adjust margin for mobile
            textAlign: 'center',
          }}
        >
          <Typography
            className="main-titlegb-mobile"
          >
          Advance Your Building’s Sustainability for a Greener Future.       
          </Typography>

          <Typography className="subtitlegb-mobile"
          >
            Start tracking your sustainability<br/> performance with ease.
          </Typography>

          <Button
            variant="contained"
            onClick={handleScrollToContact}
            className="getS-button-mobile"
          >
            Get Started
          </Button>
        </Grid>

        {/* Bouncing arrow */}
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '40px',
            zIndex: 2,
            cursor: 'pointer',
          }}
          onClick={handleScrollToPage2}
        >
          <LazyLoadImage src={arrowIcon} alt="Scroll down arrow" style={{ width: '60px', height: 'auto' }} />
        </Grid>
      </Grid>

      <Grid 
          container 
          ref={page2Ref}
          sx={{ 
              height: '100vh', 
              minHeight: '100vh', 
              scrollSnapAlign: 'start', 
              backgroundImage: `url(${background6})`,
              backgroundSize: 'cover',
              position: 'relative',
              overflow: 'hidden',
          }}
      >
          <Box
              sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  zIndex: 1,
              }}
          ></Box>

          <Box
              sx={{ 
                  zIndex: 2, 
                  position: 'relative',
                  padding: { xs: '10px', sm: '15px' }, // Adjust padding for mobile
                  maxWidth: '90%',
                  margin: 'auto',
                  height: 'auto',
                  borderRadius: '8px',
                  display: 'flex',
                  
                  flexDirection: { xs: 'column', sm: 'row' }, // Stack for mobile
              }}
          >
              {/* Left Side: Header and Subheader */}
              <Box 
                  sx={{ 
                      flex: '1',
                      padding: { xs: '10px', sm: '15px' }, 
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent: 'center', 
                      
                  }}
              >
                  <Typography className="greenbos-title-mobile"
                  >
                      GreenBOS
                  </Typography>

                  <Divider className="mainpg-divider-mobile"/>

                  <Typography className="sec2-text-mobile"
                  >
                      Your All-In-One Sustainability Solution
                  </Typography>
              </Box>

              {/* Right Side: Text Content */}
              <Box 
                  sx={{ 
                      flex: '1',
                      padding: { xs: '10px', sm: '20px' },
                      textAlign: 'left',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      maxWidth: '100%', // Allow full width on mobile
                      height: 'auto',
                  }}
              >
                  <Typography 
                    className="platform-description-mobile"
                  >
                      Our intuitive platform simplifies sustainability tracking, providing you with real-time metrics and comprehensive reports. Empower yourself to make impactful decisions that cut energy costs and enhance green credentials. With GreenBOS, gain the insights needed to drive meaningful change today.
                  </Typography>
                  <Typography 
                    className="sustainability-reports-mobile"
                  >
                      Additionally, our comprehensive sustainability reports provide in-depth insights to help you evaluate your progress and set future goals. Whether your aim is to reduce energy costs, optimize resource use, or enhance your green credentials, GreenBOS equips you with the essential insights to drive impactful change.
                  </Typography>
              </Box>
          </Box>
      </Grid>



      {/* Second Section */}
      <Grid 
        container 
        sx={{ 
          height: 'auto', // Make it auto for mobile (no fixed height)
          backgroundImage: `url(${background1})`,
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          position: 'relative',
          flexDirection: 'column', // Stack the elements vertically for mobile
          padding: '20px', // Adjust padding for mobile
        }}
      >
        {/* Background overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
          }}
        />

        {/* Main content */}
        <Grid item xs={12} sx={{ zIndex: 2, textAlign: 'left', marginBottom: '30px' }}>
          <Typography 
            className="consumption-data-title-mobile"
          >
            Why Track Your Consumption Data
          </Typography>
          
        </Grid>

        {/* Content boxes */}
        <Grid container spacing={2} sx={{ flexDirection: 'column', zIndex: 2 , marginBottom:'35px'}}>
          {/* Top row */}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Box
              className="box-hover-mobile"
            >
              <Typography className="sec3-h6-mobile">
                Complex Data & Countless Buildings
              </Typography>
              <Typography className="sec3-body2-mobile">
                Managing vast amounts of data across multiple buildings can feel like an impossible task, overwhelming and time-consuming.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <Box
              className="box-hover-mobile"
            >
              <Typography className="sec3-h6-mobile">
              Compliance with Regulations
              </Typography>
              <Typography className="sec3-body2-mobile">
                Navigating complex reporting requirements adds to the already overwhelming workload of building operations.
              </Typography>
            </Box>
          </Grid>

          {/* Bottom row */}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Box
              className="box-hover-mobile"
            >
              <Typography className="sec3-h6-mobile">
                Rising Operational Expenses
              </Typography>
              <Typography className="sec3-body2-mobile">
                Without precise insights, operational costs continue to rise unchecked, directly affecting your bottom line.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              className="box-hover-mobile"
            >
              <Typography className="sec3-h6-mobile">
                Inefficient Assets & Operations
              </Typography>
              <Typography className="sec3-body2-mobile">
                Untapped efficiency potential leads to resource waste, holding back your building&apos;s peak performance.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

{/* Sec4 ----------------------------------------------------------------------------------------*/}
      <Grid 
        container 
        sx={{ 
          height: 'auto', // Set to auto for mobile responsiveness
          backgroundImage: `url(${background4})`, // Use the correct background
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          position: 'relative',
          padding: '20px', // Add padding for mobile
          flexDirection: 'column', // Stack items vertically on mobile
        }}
      >
        {/* Background overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Keep overlay for better readability
            zIndex: 1,
          }}
        />

        {/* Title */}
        <Grid item xs={12} sx={{ zIndex: 2, textAlign: 'center', marginTop: '25px', marginBottom: '30px' }}>
          <Typography
            className="sec4-h5-mobile">
            GreenBOS: Your Solution to Sustainability Challenge
          </Typography>
        </Grid>

        {/* Content Boxes */}
        <Grid container spacing={2} sx={{ zIndex: 2 }}>
          
          {/* First Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
              className="sec4box-centered-mobile">
              <LazyLoadImage 
                src={pic2} 
                alt="Complex Data" 
                style={{ width: '100%', height: '200px', borderRadius: '10px' }} // Adjusted for mobile
              />
              <Typography className="sec4-h6-custom-mobile">
                Instant Insights at Your Fingertips
              </Typography>
              <Typography className="sec4-body2-custom-mobile">
                Gain real-time insights with live tracking. Instantly make data-driven decisions.
              </Typography>
            </Box>
          </Grid>

          {/* Second Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
              className="sec4box-centered-mobile">
              <LazyLoadImage 
                src={pic3} 
                alt="Simplified Site Management" 
                style={{ width: '100%', height: '200px', borderRadius: '10px' }} // Adjusted for mobile
              />
              <Typography className="sec4-h6-custom-mobile">
                Simplified Site Management All in One Place
              </Typography>
              <Typography className="sec4-body2-custom-mobile">
                Manage multiple building sites with ease. Filter and compare data from all locations.
              </Typography>
            </Box>
          </Grid>

          {/* Third Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
               className="sec4box-centered-mobile">
              <LazyLoadImage 
                src={pic4} 
                alt="Predictive Analytics" 
                style={{ width: '100%', height: '200px', borderRadius: '10px' }} // Adjusted for mobile
              />
              <Typography className="sec4-h6-custom-mobile">
                Predictive Analytics for a Smarter Tomorrow
              </Typography>
              <Typography className="sec4-body2-custom-mobile">
                Harness the power of advanced analytics and forecasting tools to optimize performance.
              </Typography>
            </Box>
          </Grid>

          {/* Fourth Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
               className="sec4box-centered-mobile">
              <LazyLoadImage 
                src={pic5} 
                alt="Optimized Operations" 
                style={{ width: '100%', height: '200px', borderRadius: '10px' }} // Adjusted for mobile
              />
              <Typography className="sec4-h6-custom-mobile">
                Optimized Operations Without the Effort
              </Typography>
              <Typography className="sec4-body2-custom-mobile">
                Maximize efficiency with automated systems that save costs and enhance sustainability.
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </Grid>

      <Grid 
        container 
        sx={{ 
          height: 'auto', // Set to auto for mobile responsiveness
          backgroundImage: `url(${background3})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          color: 'white',
          position: 'relative',
          padding: '20px', // Add padding for mobile
          flexDirection: 'column', // Stack items vertically on mobile
        }}
      >
        {/* Background overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)', 
            zIndex: 1,
          }}
        />

        {/* Title */}
        <Grid item xs={12} sx={{ zIndex: 2, textAlign: 'center', marginBottom: '20px' }}>
          <Typography
            className="sec5-h4-custom-mobile">
             Data-Driven Insights by Greenbos
          </Typography>
        </Grid>

        {/* Content Boxes */}
        <Grid container spacing={2} sx={{ flexDirection: 'column', zIndex: 2 }}>

          {/* First Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
              className="sec5box-custom-mobile">
              <LazyLoadImage 
                src={icon1} 
                alt="Electricity Usage" 
                style={{ width: '50%', height: 'auto', borderRadius: '10px' }} 
              />
              <Typography className="sec5-h6-custom-mobile">
                Electricity Usage
              </Typography>
              <Typography className="sec5-body2-custom-mobile">
                Optimize energy consumption by understanding your building&apos;s electricity patterns.
              </Typography>
            </Box>
          </Grid>

          {/* Second Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
              className="sec5box-custom-mobile">
              <LazyLoadImage 
                src={icon2} 
                alt="Waste Management" 
                style={{ width: '50%', height: 'auto', borderRadius: '10px' }} 
              />
              <Typography className="sec5-h6-custom-mobile">
                Waste Management
              </Typography>
              <Typography className="sec5-body2-custom-mobile">
              Monitor waste generation
                in waste disposal and
                recycling practices effectively. 
              </Typography>
            </Box>
          </Grid>

          {/* Third Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
              className="sec5box-custom-mobile">
              <LazyLoadImage 
                src={icon3} 
                alt="Water Consumption" 
                style={{ width: '50%', height: 'auto', borderRadius: '10px' }} 
              />
              <Typography className="sec5-h6-custom-mobile">
                Water Consumption
              </Typography>
              <Typography className="sec5-body2-custom-mobile">
                Gain insights into water usage and uncover opportunities for conservation and cost savings.
              </Typography>
            </Box>
          </Grid>

          {/* Fourth Box */}
          <Grid item xs={12} sx={{ marginBottom: '20px', width: '100%' }}>
            <Box
              className="sec5box-custom-mobile">
              <LazyLoadImage 
                src={icon4} 
                alt="Environmental Impact" 
                style={{ width: '50%', height: 'auto', borderRadius: '10px' }} 
              />
              <Typography className="sec5-h6-custom-mobile">
                Environmental 
              </Typography>
              <Typography className="sec5-body2-custom-mobile">
              Monitor  environmental footprint
              and make necessary changes
              for a sustainable future.
              </Typography>
            </Box>
          </Grid>

          {/* Fifth Box */}
          <Grid item xs={12} sx={{ marginBottom: '25px', width: '100%' }}>
            <Box
              className="sec5box-custom-mobile">
              <LazyLoadImage 
                src={icon5} 
                alt="CO2 Emissions" 
                style={{ width: '50%', height: 'auto', borderRadius: '10px' }} 
              />
              <Typography className="sec5-h6-custom-mobile">
                CO2 Emissions
              </Typography>
              <Typography className="sec5-body2-custom-mobile">
                Monitor your carbon footprint to meet regulations and drive sustainability success.
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </Grid>

      <Grid 
          ref={contactRef}
          container
          justifyContent="center" // Center items horizontally
          alignItems="center"     // Center items vertically
          sx={{ 
            height: 'auto',  // Auto height for mobile responsiveness
            backgroundImage: `url(${background5})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: { xs: '10px', md: '20px' }, // Adjust padding for mobile
            position: 'relative', // To position the overlay and content correctly
          }}
        >
          {/* Overlay to make content more visible */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',  // Darker overlay for better contrast
              zIndex: 1,
            }}
          ></Box>

          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            sx={{ 
              zIndex: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.85)',  // Semi-transparent white background for better contrast
              padding: { xs: '20px', md: '40px' },  // Adjust padding for mobile
              borderRadius: '10px',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Optional shadow for depth
              maxWidth: '1000px',  // Limit the width of the overall box
              gap: '20px',  // Gap between the contact info and the form
              flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on mobile
              marginTop:'50px',
              marginBottom:'50px'
            }}
          >
            {/* Contact Information Section */}
            <Box
              className="sec6contact-box-custom-mobile">
              <Typography 
                className="sec6-h4-custom-mobile">
                Get in Touch <br/>With Us Today
              </Typography>

              {/* Contact Details */}
              <Box className="sec6contactdetails-box-item-mobile">
                <Phone className="sec6icon-style-mobile"/>
                <Typography  className="sec6-contact-mobile">
                  +603-7497 2558
                </Typography>
              </Box>

              <Box className="sec6contactdetails-box-item-mobile">
                <Email className="sec6icon-style-mobile"/>
                <Typography className="sec6-contact-mobile">
                sales@squarecloud.asia
                </Typography>
              </Box>

              <Box className="sec6contactdetails-box-item3-mobile">
                <LocationOn className="sec6icon-style-mobile"/>
                <Typography className="sec6-contact-mobile">
                  D-61-3A Level 3A Jaya One, No. 72A Jalan Prof Diraja Ungku Aziz, 46200 Petaling Jaya, Selangor
                </Typography>
              </Box>

              <Box className="sec6contactdetails-box-item4-mobile">
                <Public className="sec6icon-style-mobile"/>
                <Typography className="sec6-contact-mobile">
                  <a href="https://squarecloudasia.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>
                    squarecloudasia.com
                  </a>
                </Typography>
              </Box>
            </Box>

            {/* Contact Form Section */}
            <Box
              sx={{
                width: { xs: '100%', md: '55%' },  // Full width on mobile, 55% on desktop
              }}
            >
              <form ref={form} onSubmit={sendEmail} style={{ width: '100%' }}>
                <TextField 
                  label="Your Name" 
                  name="user_name" 
                  fullWidth 
                  margin="normal" 
                  required 
                  sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                />

                <TextField 
                  label="Company Name" 
                  name="user_company" 
                  fullWidth 
                  margin="normal" 
                  required 
                  sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                />

                <TextField 
                  label="Phone Number" 
                  name="user_phone" 
                  type="tel" 
                  fullWidth 
                  margin="normal" 
                  required 
                  sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                />

                <TextField 
                  label="Your Email" 
                  name="user_email" 
                  type="email" 
                  fullWidth 
                  margin="normal" 
                  required 
                  sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                />

                <TextField 
                  label="Message" 
                  name="message" 
                  fullWidth 
                  margin="normal" 
                  required 
                  multiline 
                  rows={4}
                  sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                />
                <Button 
                  variant="contained" 
                  type="submit"
                  fullWidth
                  className={`sec6submit-button-mobile ${success ? "success" : ""}`}
                  style={{
                    backgroundColor: success ? '#4CAF50' : '#1976d2', // Dynamic background color
                  }}
                  disabled={success}
                >
                  {success ? "Success!" : "Submit"}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
    </>
);

  const DesktopVersion = () => (
    <Box sx={{ height: '100vh', scrollSnapType: 'y mandatory', overflowY: 'scroll', scrollBehavior: 'smooth' ,position: 'relative' ,minHeight: '100vh'}}>

    <Grid
      container
      sx={{
        scrollSnapAlign: 'start', 
        minHeight: '100vh',             // Prevents shrinking when zooming
        backgroundImage: `url(${background2})`, // Update with your image path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        color: 'white',
        textAlign: 'center',
        padding: '0 5vh',
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1,
        }}
      ></Box>
      <Box
          sx={{
            zIndex: 2,
            padding: '2vw', // Add padding around the content
            width: '100%', // Full width of the grid container
            maxWidth: '100vw', // Limit the width of the content box
          }}
        >
      {/* Content */}
      <Grid
        item
        xs={12}
        sx={{
          zIndex: 2, // Make sure content is above overlay
          marginTop: '17vh',
          textAlign: 'center',
        }}
      >
        <Typography className="s1main-heading-web">
           Advance Your Building’s Sustainability for a Greener Future.
        </Typography>

        <Typography
          className="s1tracking-info-web">
          Start tracking your sustainability performance with ease.
        </Typography>

        <Button
          variant="contained"
          onClick={handleScrollToContact}
          className="s1get-started-button-web"
        >
          Get Started
        </Button>
      </Grid>

      {/* Bouncing arrow */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: '4vh',
          zIndex: 2,
          cursor: 'pointer',
        }}
        onClick={handleScrollToPage2}
      >
        <LazyLoadImage src={arrowIcon} alt="Scroll down arrow" style={{ width: '5vw',height:'auto' }} />
      </Grid>
      </Box>
    </Grid>

{/*Section ---------------------------------------------------------*/ }
      <Grid 
        container 
        ref={page2Ref}
        sx={{ 
          height: '100vh', 
          minHeight: '100vh', // Prevents shrinking when zooming
          scrollSnapAlign: 'start', 
          backgroundImage: `url(${background6})`,
          backgroundSize: 'cover', // Adjust to cover or contain based on your design
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
          }}
        ></Box>

        <Box
          sx={{ 
            zIndex: 2, 
            position: 'relative', // To ensure this content is above the overlay
            padding: '15px', // Padding around the container
            maxWidth: '90%', // Limit the width of the container
            margin: 'auto', // Center the box
            height: 'auto', // Adjust height as necessary
            borderRadius: '8px',
            //backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light background for contrast
            display: 'flex', // Use flexbox to position left and right sections
          }}
        >
          {/* Left Side: Header and Subheader */}
          <Box 
            className="s2content-box-web">
            <Typography className="s2content-title-web1">
              GreenBOS
            </Typography>

            <Divider className="s2content-divider-web1"/>

            <Typography className="s2content-subtitle-web1">
              Your All-In-One Sustainability Solution
            </Typography>
          </Box>

          {/* Right Side: Text Content */}
          <Box 
            className="s2content-box2-web">
            <Typography className="s2content-paragraph2-web1">
              Our intuitive platform simplifies sustainability tracking, providing you with real-time metrics and comprehensive reports. Empower yourself to make impactful decisions that cut energy costs and enhance green credentials. With GreenBOS, gain the insights needed to drive meaningful change today.
              </Typography>
            <Typography className="s2content-paragraph2-web1 s2content-paragraph-second2-web1">
              Additionally, our comprehensive sustainability reports provide in-depth insights to help you evaluate your progress and set future goals. Whether your aim is to reduce energy costs, optimize resource use, or enhance your green credentials, GreenBOS equips you with the essential insights to drive impactful change.
              </Typography>
          </Box>
        </Box>
      </Grid>



      {/* Section 1 */}
      <Grid 
        container
        sx={{ 
          height: '100vh', 
          minHeight: '100vh',             // Prevents shrinking when zooming
          scrollSnapAlign: 'start', 
          backgroundImage: `url(${background1})`,
          backgroundSize:'100%',
          position: 'relative',  // To allow absolute positioning inside
          overflow: 'hidden',
        }}
      >
      
        <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          zIndex: 1,
        }}
      ></Box>
        <Grid item xs={12} md={5} sx={{ alignContent:'center',zIndex: 2}}>
          {/* Add the Box component here */}
          

          <Typography className="s3section-title-web">
            Why Track Your Consumption Data
          </Typography>

          <Typography className="s3subtitle-text-web">
            Gain Control Over Complexity, Costs, and Efficiency
          </Typography>

        </Grid>

        <Grid item xs={12} md={7} container sx={{ position: 'relative',zIndex: 2 }}>
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            padding: '1vh 2vw', // Padding around the Box
            display: 'grid', // Set the display to grid for a more flexible layout
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, // Two columns for larger screens, single column for small
            gap: '15px', // Gap between grid items
          }}
        >
      {/* Grid Item 1 */}
      <Box
        className="s3custom-box-web">
        <Typography
          className="s3title-text-web">
          Complex Data & Countless Buildings
        </Typography>
        <Typography
          className="s3body-text-web">
        Managing extensive data across various buildings
        can be challenging, requiring substantial time
        and dedicated effort.
        </Typography>
      </Box>

      {/* Grid Item 2 */}
      <Box
        className="s3custom-hover-box2-web">
        <Typography
          className="s3title-text-web">
         Compliance with Regulations
        </Typography>
        <Typography
          className="s3body-text-web">
        Navigating complex reporting demands
        intensifies the existing workload
        that building operations must manage.     
        </Typography>
      </Box>

      {/* Grid Item 3 */}
      <Box
        className="s3hover-box3-web">
        <Typography
          className="s3title-text-web">
          Rising Operational Expenses
        </Typography>
        <Typography
          className="s3body-text-web">
          Operational costs rise unchecked
          without accurate insights,
          directly impacting profitability.
        </Typography>
      </Box>

      {/* Grid Item 4 */}
      <Box
        className="s3custom-box4-web">
        <Typography
          className="s3title-text-web">
          Inefficient Assets & Operations
        </Typography>
        <Typography
         className="s3body-text-web">
        Unrealized efficiency leads to wastage,
        limiting your building’s ability to reach
        its optimal operational performance.
        </Typography>
      </Box>
      </Box>
      </Grid>

      </Grid>

      {/* Section 2 */}
        <Grid 
          container 
          sx={{ 
            height: '100vh', 
            scrollSnapAlign: 'start', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundImage: `url(${background4})`, // Update with your image path
            backgroundSize:'100%',
            color: 'white' ,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
        <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          zIndex: 1,
        }}
      ></Box>
      
          <Grid item xs={12} md={10} sx={{ zIndex: 2}} >
            <Typography  className="s4title-text-web"> GreenBOS: Your Solution to Sustainability Challenge</Typography>
          </Grid>

          <Grid 
            container 
            justifyContent="center"   // Center the grid items horizontally
            alignItems="center"       // Center the grid items vertically
            sx={{ padding: { xs: '1vw', sm: '1vw', md: '1vw' } }}>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: 'repeat(4, 1fr)' }, // Adjust grid for small and larger screens
              gap: '1.5vw', // Gap between grid items
              padding: '20px',
              zIndex: 2,
            }}
          >
            {/* Grid Item 1 */}
            <Box
              className="s4hover-box-web">
              <LazyLoadImage src={pic2} alt="Complex Data" style={{ width: '100%', height: '33vh', borderRadius: '10px' }} />
              <Typography className="s4insights-title-web">
                Instant Insights at Your Fingertips
              </Typography>
              <Typography className="s4insights-description-web">
              Gain live insights with real-time tracking,
              empower data-driven decisions instantly.
              Monitor energy, water, and fuel usage
              across all buildings for smarter operations.
              </Typography>
            </Box>

            {/* Grid Item 2 */}
            <Box
              className="s4hover-box-web">
              <LazyLoadImage src={pic3} alt="Complex Data" style={{ width: '100%', height: '33vh', borderRadius: '10px' }} />
              <Typography className="s4insights-title-web">
                Simplify Site Management All in One Place
              </Typography>
              <Typography className="s4insights-description-web">
              Manage multiple building sites with
              ease. Filter and compare data across
              all locations on a single dashboard,
              streamlining your operations smoothly.
              </Typography>
            </Box>

            {/* Grid Item 3 */}
            <Box
              className="s4hover-box-web">
              <LazyLoadImage src={pic4} alt="Complex Data" style={{ width: '100%', height: '33vh', borderRadius: '10px' }} />
              <Typography className="s4insights-title-web">
                Predictive Analytics for a Smarter Tomorrow
              </Typography>
              <Typography className="s4insights-description-web">
                Harness the power of advanced analytics and forecasting tools. Anticipate trends, optimize performance, and stay ahead of inefficiencies with data-driven insights.
              </Typography>
            </Box>

            {/* Grid Item 4 */}
            <Box
              className="s4hover-box-web">
              <LazyLoadImage src={pic5} alt="Complex Data" style={{ width: '100%', height: '33vh', borderRadius: '10px' }} />
              <Typography className="s4insights-title-web">
                Optimized Operations Without the Effort
              </Typography>
              <Typography className="s4insights-description-web">
              Maximize efficiency with automation that saves costs,
              reduces manpower, and enhances sustainability. Boost resources and drive profitability
              with GreenBOS.
              </Typography>
            </Box>
          </Box>
          </Grid>
        </Grid>

      {/* Section 3 */}
      <Grid 
        container 
        sx={{ 
          height: '100vh', 
          scrollSnapAlign: 'start', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundImage: `url(${background3})`, 
          backgroundSize:'100%',
          color: 'white' ,
          position: 'relative',
          overflow: 'hidden',

        }}
      >

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
          }}
        ></Box>

        <Box
            sx={{
              zIndex: 2, // Ensures content is above the overlay
              width: '100%', // Full width
              padding: { xs: '20px', md: '0 40px' }, // Padding for small and larger screens
              maxWidth: '100vw', // Set a maximum width for larger screens
              margin: '0 auto', // Center the box horizontally
            }}
          >
            {/* Title */}
            <Typography
              className='s5data-driven-title-web'
            >
              Data-Driven Insights by Greenbos
            </Typography>
          </Box>

        <Grid 
          container 
          justifyContent="center"   // Center the grid items horizontally
          alignItems="center"       // Center the grid items vertically
          sx={{ padding: '20px' }}
        >
          {/* Top row: 2 items */}
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: 'repeat(5, 1fr)' }, // Adjust to show 3 items per row on medium screens
              gap: '1vw',
              zIndex: 2,
              width: '100%', // Ensure the box takes full width
            }}
          >
            {/* Grid Item 1 */}
            <Box
              className='s5insight-box-web'>
              <LazyLoadImage src={icon1} alt="Electricity Usage" style={{ width: '60%', height: '18vh', borderRadius: '10px' }} />
              <Typography
                className='s5electricity-usage-title-web'>
                Electricity Usage
              </Typography>
              <Typography
                className='s5electricity-usage-description-web'>
                Optimize energy consumption by understanding your building&apos;s electricity patterns.
              </Typography>
            </Box>

            {/* Grid Item 2 */}
            <Box
              className='s5data-box2-web'>
              <LazyLoadImage src={icon2} alt="Waste Management" style={{ width: '65%', height: '18vh', borderRadius: '10px' }} />
              <Typography
                className='s5electricity-usage-title-web'>
                Waste Management
              </Typography>
              <Typography
                className='s5electricity-usage-description-web'>
              Monitor waste generation
              in waste disposal and
              recycling practices effectively.         
                   </Typography>
            </Box>

            {/* Grid Item 3 */}
            <Box
              className='s5transform-box3-web'>
              <LazyLoadImage src={icon3} alt="Water Consumption" style={{ width: '65%', height: '18vh', borderRadius: '10px' }} />
              <Typography
                className='s5electricity-usage-title-web'>
                Water Consumption
              </Typography>
              <Typography
                className='s5electricity-usage-description-web'>
                Gain insights into water usage and uncover opportunities for conservation and cost savings.
              </Typography>
            </Box>

            {/* Grid Item 4 */}
            <Box
              className='s5transform-box-negative-margin-web'>
              <LazyLoadImage src={icon4} alt="Environmental Impact" style={{ width: '65%', height: '18vh', borderRadius: '10px' }} />
              <Typography
                className='s5electricity-usage-title-web'>
                Environmental
              </Typography>
              <Typography
                className='s5electricity-usage-description-web'>
              Monitor  environmental footprint
              and make necessary changes
              for a sustainable future.


              </Typography>
            </Box>

            {/* Grid Item 5 */}
            <Box
              className='s5custom-box-negative-margin5-web'>
              <LazyLoadImage src={icon5} alt="CO2 Emissions" style={{ width: '65%', height: '18vh', borderRadius: '10px' }} />
              <Typography
                className='s5electricity-usage-title-web'>
                CO2 Emissions
              </Typography>
              <Typography
                className='s5electricity-usage-description-web'>
                Monitor your carbon footprint to meet regulations and drive sustainability success.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Contact Us */}
      <Grid 
        ref={contactRef}
        container
        justifyContent="center" // Center items horizontally
        alignItems="center"     // Center items vertically
        sx={{ 
          height: '100vh',  // Full viewport height
          backgroundImage: `url(${background5})`, 
          backgroundSize:'cover',
          backgroundPosition: 'center',
          scrollSnapAlign: 'start', 
          padding: '20px',
          position: 'relative', // To position the overlay and content correctly
        }}
      >
        {/* Overlay to make content more visible */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',  // Darker overlay for better contrast
            zIndex: 1,
          }}
        ></Box>

        <Box
            className="s6contact-container-web"
          >
            {/* Contact Information Section */}
            <Box
              className="s6contact-box-web">
              <Typography 
                className="s6contact-heading-web">
                Get in Touch <br/>With Us <br/> Today
                
              </Typography>
              
              {/*<Box
                sx={{
                  position: 'absolute',
                  top: '240px',  
                  left: { xs: '50px', md: '550px' },  // Responsive positioning for different screen sizes
                  width: '150px',
                  height: '150px',
                  borderRadius: '20px', 
                  backgroundImage: `url(${SQ})`,
                  backgroundSize: '150%',
                  backgroundPosition: 'center',
                  zIndex: 2,  
                }}
              ></Box>*/}

              {/* Contact Details */}
              <Box className="s6contact-info-web">
                <Phone className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                  +603-7497 2558
                </Typography>
              </Box>

              <Box className="s6contact-info-web">
                <Email className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                sales@squarecloud.asia
                </Typography>
              </Box>

              <Box className="s6flex-start-box-icon-web">
                <LocationOn className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                  D-61-3A Level 3A Jaya One, No. 72A Jalan Prof Diraja Ungku Aziz, 46200 Petaling Jaya, Selangor
                </Typography>
              </Box>

              <Box className="s6flex-start-box-icon2-web">
                <Public className="s6phone-icon-web"/>
                <Typography className="s6phone-text-web">
                  <a href="https://squarecloudasia.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>
                    squarecloudasia.com
                  </a>
                </Typography>
              </Box>
            </Box>

            {/* Contact Form Section */}
            <Box
              sx={{
                width: '55%' ,  // Responsive width for the form
              }}
            >
              <form ref={form} onSubmit={sendEmailD} style={{ width: '100%' }}>
              
                <TextField 
                  label="Your Name" 
                  name="user_name" 
                  fullWidth 
                  margin="normal" 
                  required 
           
                  className="s6styled-textfield-web"
                />

                <TextField 
                  label="Company Name" 
                  name="user_company" 
                  fullWidth 
                  margin="normal" 
                  required 
                   className="s6styled-textfield-web"
                />

                <TextField 
                  label="Phone Number" 
                  name="user_phone" 
                  type="tel" 
                  fullWidth 
                  margin="normal" 
                  required 
                  className="s6styled-textfield-web"
                />

                <TextField 
                  label="Your Email" 
                  name="user_email" 
                  type="email" 
                  fullWidth 
                  margin="normal" 
                  required 
                  className="s6styled-textfield-web"
                />

                <TextField 
                  label="Message" 
                  name="message" 
                  fullWidth 
                  margin="normal" 
                  required 
                  multiline 
                  rows={4}
                  className="s6styled-textfield-web"
                />

            <Button 
              variant="contained" 
              type="submit"
              fullWidth
              className={`sec6submit-button-desktop ${success ? "success" : ""}`}
              disabled={success}
            >
              {success ? "Success!" : "Submit"}
            </Button>
              </form>
            </Box>
          </Box>
      </Grid>
    </Box>
  );

  return (
    <Box>
      {/* Render mobile or desktop version based on state */}
      {isMobile ? <MobileVersion /> : <DesktopVersion />}
    </Box>
  );
}

export default Page2;